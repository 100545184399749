
@import 'src/variables.scss';

.WidgetThemesColor {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;

    @each $name, $color in $theme-colors {
        &.WidgetThemesColor--#{$name} {
          background: $color;
        }
    }
    
    &__wrapper {
        padding: 8px;
        background-color: transparent;
        border: none;
        box-sizing: border-box;
        border-radius: 8px;

        &--isSelected {
            background-color: #ff00d0;
        }

        &--hasDarkBackground {
            background: #05051D;
        }
    }
}
