@import "./src/variables.scss";

.GroupItem {
  &__groupItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: none;
    padding: 0 10px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: map-get($fresh-theme-colors, 'black');
    text-decoration: none;
    transition: .225s all ease-in-out;

    &_disabled {
      cursor: auto;
      pointer-events: none;
    }

    &_active {
      color: map-get($fresh-theme-colors, 'pink');
    }

    &_grey {
      color: map-get($fresh-theme-colors, 'grey');
    }

    &:hover &:not(._disabled) {
      color: map-get($fresh-theme-colors, 'black');
      text-decoration: none;

      .GroupsLine__addGroup {
        background: map-get($fresh-theme-colors, 'pink');
      }

      .GroupsLine__addGroupIcon {
        stroke: #FFFFFF;
      }
    }

    &_noHover {
      &:hover {
        .GroupsLine__addGroup {
          background: transparent;
        }

      }
    }

    &:focus {
      outline: none;
    }
  }

  &__groupTitle {
    margin-top: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}