@import './src/variables.scss';

.InviteTeamMemberModal {
  &__drop {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 12px;
    border: 1px solid map-get($fresh-theme-colors, 'grey-light');
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }

  &__dropItem {
    font-size: 14px;
    line-height: 46px;
    cursor: pointer;
    padding: 0 16px;
    transition: 0.2s ease all;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      line-height: 40px;
    }

    &:hover {
      background: map-get($fresh-theme-colors, 'grey-light');
    }
  }

  &__input {
    &_disabled {
      input {
        color: rgba($color: map-get($fresh-theme-colors, 'black'), $alpha: 0.5) !important;
      }
    }
  }

  &__desc {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: map-get($fresh-theme-colors, 'black');
  }

  &__lastDesc {
    margin-bottom: 40px !important;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      margin-bottom: 24px !important;
    }
  }

  .FreshDrop__dropdown {
    width: 100%;
  }
}