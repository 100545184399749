@import './src/variables.scss';

.SubscriptionCancelledCard {
  &__title {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    color: map-get($fresh-theme-colors, 'black');
    margin-bottom: 16px;
  }

  &__desc {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: map-get($theme-colors, 'black');
    margin: 0;
  }

  &__time {
    font-weight: 700;
  }
}