@import 'src/variables.scss';

.PlansModal {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-size: 30px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 14px;
    color: map-get($fresh-theme-colors, 'black');

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 10px;
    }
  }

  &__desc {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: map-get($fresh-theme-colors, 'black');
    text-align: center;
    max-width: 454px;
    margin-bottom: 20px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }

  &__switch {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 40px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      margin-bottom: 20px;
    }
  }

  &__switchDesc {
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'pink');
  }

  &__switchIcon {
    width: 20px;
    height: 20px;
    fill: map-get($fresh-theme-colors, 'grey');

    &_white {
      fill: #FFFFFF;
    }
  }

  .FreshButton {
    .PlansModal__switchIcon {
      transition: .225s all ease-in-out;
    }

    &:hover {
      .PlansModal__switchIcon {
        fill: #FFFFFF;
      }
    }
  }
}