@import 'src/variables.scss';

.Header {
  padding-right: 24px;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding-right: 0;
  }

  &__subscriptionTextContainer {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__separator {
    background: map-get($fresh-theme-colors, 'grey-light');
    height: 10px;
    width: 1px;
    margin: 0 24px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      display: none;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 58px;
    background: #ffffff;
    padding: 0 30px;
    border-radius: 0px 0px 16px 16px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      height: auto;
      padding: 17px 20px;
      padding-left: 54px;
    }
  }

  &__subscription {
    display: flex;
    align-items: center;
    gap: 8px;
    color: map-get($theme-colors, 'black');
  }

  &__subscriptionContainer {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__subscriptionText {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }

  &__subscriptionLimits {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    color: #A49BA4;
    line-height: 100%;
  }

  &__brand {
    margin-right: 20px;
  }

  &__col {
    display: flex;
    align-items: center;

    &_centered {
      justify-content: center;
    }

    &_right {
      justify-content: flex-end;


      @media (max-width: map-get($grid-breakpoints, lg)) {
        gap: 20px;
      }
    }
  }

  &__authControlDropdownItemIcon {
    width: 20px;
    margin-right: 12px;
    transition: .225s all ease-in-out;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 15px;
      margin-right: 8px;
    }
  }

  &__authControlContainer {
    display: flex;
    flex-direction: column;
  }

  &__authControlText {
    @media (max-width: map-get($grid-breakpoints, lg)) {
      display: none;
    }

    &_small {
      font-size: 12px;
      line-height: 15px;
    }
  }

  &__authControl {
    display: flex;
    align-items: center;
    border: none;
    color: map-get($fresh-theme-colors, 'grey');
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    transition: .225s all ease-in-out;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      line-height: 16px;
    }

    &:hover {
      color: map-get($fresh-theme-colors, 'black');

      .Header__authControlIcon {
        stroke: map-get($fresh-theme-colors, 'pink');
      }

      .Header__authControlArrow {
        stroke: map-get($fresh-theme-colors, 'black');
      }
    }
  }

  &__authControlDropdown {
    width: 155px !important;
    align-items: flex-start !important;
    height: auto !important;
    margin-left: auto !important;
    margin-right: -15px !important;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 100px !important;
      margin-top: 0;
    }
  }

  &__authControlIcon {
    width: 20px;
    margin-right: 12px;
    transition: .225s all ease-in-out;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 14px;
      margin-right: 8px;
    }
  }

  &__authControlArrow {
    margin-left: 12px;
    transition: .225s all ease-in-out;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      margin-left: 4px;
    }
  }

  &__authDropdown {
    background: #18182E;
    border: 1px solid map-get($theme-colors, 'black');
    border-radius: 12px;
  }

  &__logout {
    background: transparent;
    outline: none;
    appearance: none;
    border: 0;
    color: #FFFFFF;
    font-size: 12px;
    line-height: 15px;
  }

  &__authControlDropdownItem {
    color: map-get($fresh-theme-colors, 'black');
    padding: 12px;
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: .225s all ease-in-out;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      padding: 8px;
      font-size: 12px;
    }

    &:first-child {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    &:last-child {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    &:hover,
    &:focus {
      background-color: #F8F7F8;
      color: map-get($fresh-theme-colors, 'black');
      text-decoration: none;

      .Header__authControlDropdownItemIcon {
        stroke: map-get($fresh-theme-colors, 'black');

        &_fill {
          stroke: none;
          fill: map-get($fresh-theme-colors, 'black');
        }
      }
    }

    &:visited {
      color: map-get($fresh-theme-colors, 'black');
    }
  }
}

.show {
  .Header__authControl {
    color: map-get($fresh-theme-colors, 'black');
  }

  .Header__authControlIcon {
    stroke: map-get($fresh-theme-colors, 'pink');
  }

  .Header__authControlArrow {
    stroke: map-get($fresh-theme-colors, 'pink');
  }
}