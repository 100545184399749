@import 'src/variables.scss';

.SettingsCollapse {
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  border-bottom: 1px solid #05051D;

  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0;
  }

  &__titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;

    &_open {
      margin-bottom: 12px;
    }
  }

  &__subtitleContainer {
    display: flex;
  }

  &__subtitle {
    margin-left: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    padding: 4px 8px;
    background-color: map-get($theme-colors, 'black');
    border-radius: 12px;
  }

  &__arrow {
    margin: 0;
    padding: 0;
  }

  &__chevron {
    transition: 0.3s ease all;

    &_open {
      transform: scale(1, -1);
    }
  }
}