@import './src/variables.scss';

.StoryChoosePreview {
  width: 174px;
  height: 309px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
  position: relative;

  &:hover &__overlay {
    opacity: 1;
  }

  &_filled {
    &::after {
      content: "";
      opacity: 1;
      transition: .25s opacity ease-in;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(253, 25, 204, 0.5), rgba(253, 25, 204, 0.5));
    }

    &:hover {
      &::after {
        opacity: 0;
      }
    }
  }

  &__checkbox {
    position: absolute;
    top: 11px;
    right: 11px;
    z-index: 4;
  }

  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    opacity: 0;
    width: 100%;
    height: 100%;
    background: rgba(164, 155, 164, 0.8);
    align-items: center;
    justify-content: center;
    transition: .25s opacity ease-in;
    z-index: 3;
  }

  &__mainButtons {
    display: flex;
    flex-direction: column;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');
    background: map-get($fresh-theme-colors, 'grey-background');
    border-radius: 12px;
    transition: .225s all ease-in-out;
    width: 103px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      padding: 8px;
      font-size: 12px;
      border-radius: 10px;
    }

    &:hover {
      text-decoration: none;
      color: #FFFFFF;
      background: map-get($fresh-theme-colors, 'pink');
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__btnIcon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 8px;
    transition: .225s all ease-in-out;
    stroke: map-get($fresh-theme-colors, 'black');

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }

  &__btn:hover &__btnIcon {
    stroke: #FFFFFF;
  }
}