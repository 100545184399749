.StoryEditorMoveable {
  display: block !important;

  --moveable-color: #ff00d0 !important;
  --bounds-color: #ff00d0 !important;

  .moveable-guideline {
    --moveable-color: #EB5757 !important;
    --bounds-color: #EB5757 !important;
  }

  .moveable-line.moveable-vertical.moveable-bold{
    width: 1px !important;
  }

  .moveable-line.moveable-horizontal.moveable-bold{
    height: 1px !important;
  }

  .moveable-direction {
    display: block;
  }

  .moveable-control {
    width: 7px !important;
    height: 7px !important;
    margin-top: -3.5px !important;
    margin-left: -3.5px !important;
    border-radius: 2px !important;
    background: #fff !important;
    border: 1px solid #ff00d0 !important; 
  }

  .moveable-control-box {
    --bounds-color: #ff00d0 !important;
    --moveable-color: #ff00d0 !important;
  }

  .moveable-area-line {
    display: none;
  }

  .moveable-area {
    cursor: move;
    // pointer-events: none;
  }
}