@import './src/variables.scss';

.FreshButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 12px;
  padding: 14px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background: map-get($fresh-theme-colors, 'pink');
  border: none;
  outline: none;
  cursor: pointer;
  transition: .225s all ease-in-out;
  border: 1px solid transparent;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding: 10px;
    font-size: 12px;
    line-height: 16px;
    border-radius: 10px;
  }

  &:hover {
    background: map-get($fresh-theme-colors, 'pink-dark');
    color: #ffffff;
  }

  &:disabled,
  &_disabled {
    opacity: 0.5;
    cursor: auto;
  }

  &_color {
    &_grey {
      background: map-get($fresh-theme-colors, 'grey-background');
      color: map-get($fresh-theme-colors, 'black');

      &:hover {
        background: map-get($fresh-theme-colors, 'grey');
      }
    }

    &_grey-light {
      background: map-get($fresh-theme-colors, 'grey-light');
      color: map-get($fresh-theme-colors, 'black');

      &:hover {
        background: map-get($fresh-theme-colors, 'grey');
      }
    }

    &_transparent-grey {
      background: transparent;
      color: map-get($fresh-theme-colors, 'grey');
      border: 1px solid map-get($fresh-theme-colors, 'grey');

      &:hover {
        color: #ffffff;
        background: map-get($fresh-theme-colors, 'grey');
      }
    }

    &_transparent-grey-light {
      background: transparent;
      color: map-get($theme-colors, 'black');
      border: 1px solid map-get($fresh-theme-colors, 'grey-light');

      &:hover {
        color: map-get($theme-colors, 'black');
        background: map-get($fresh-theme-colors, 'grey-light');
      }
    }

    &_black {
      background: map-get($fresh-theme-colors, 'black');
      color: #ffffff;
    }

    &_dark-grey {
      background: map-get($fresh-theme-colors, 'grey');
      color: #ffffff;

      &:hover {
        background: map-get($fresh-theme-colors, 'black');
      }
    }

    &_dark {
      background: map-get($theme-colors, 'black');
      color: #ffffff;

      &:hover {
        background: map-get($theme-colors, 'black');
      }
    }

    &_white {
      background: #FFFFFF;
      color: map-get($fresh-theme-colors, 'black');

      &:hover {
        background: map-get($fresh-theme-colors, 'grey-light');
        color: map-get($fresh-theme-colors, 'black');
      }
    }
  }

  &_size {
    &_md {
      width: auto;

      @media (max-width: map-get($grid-breakpoints, lg)) {
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    &_sm {
      width: auto;
      font-size: 12px;
      line-height: 16px;
      padding: 8px;

      @media (max-width: map-get($grid-breakpoints, lg)) {
        font-size: 10px;
        line-height: 14px;
        padding: 6px;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  &__text {
    white-space: nowrap;

    &_marginLeft {
      margin-left: 8px;
    }
  }
}