$theme-colors: (
  'purple': linear-gradient(72.4deg, #ae13ab 2.52%, #890eea 95.95%),
  'blue': #00b2ff,
  'darkBlue': #366efe,
  'white': #ffffff,
  'green': #44d937,
  'orange': #ffa93d,
  'orangeRed': #ff4c25,
  'yellow': #f3cc00,
  'black': #05051d,
  'grey': #18182E,
);

$fresh-theme-colors: (
  'black': #2B1E2A,
  'red': #EE2E2E,
  'grey': #A49BA4,
  'pink': #FD19CC,
  'pink-dark': #E41ABA,
  'grey-light': #E9E6E9,
  'grey-background': #F8F7F8
);

$blue: #edf5ff;
$blue-dark: #d9e3eb;
$pink: #ff00d0;
$red: #c13939;
$green: #32de84;
$grey-blue: #1A344B;
$light-grey: #AFAFAF;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

@mixin use-dark-scroll {
  &::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: #3f3f5c;
    border-radius: 10px;

    border: 5px solid #05051d;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-track-piece:start {
    background: transparent;
  }

  &::-webkit-scrollbar-track-piece:end {
    background: transparent;
  }
}

@mixin use-light-scroll {
  &::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: map-get($fresh-theme-colors, 'grey-light');
    border-radius: 10px;
    border: 5px solid #ffffff;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}