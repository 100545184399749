@import '/src/variables.scss';

.SettingsInputText {
  position: relative;

  &__input {
    width: 100%;
    height: 80px;
    height: 34px;
    background: #05051D;
    border-radius: 8px;
    color: #ffffff;
    padding: 0 12px;
    font-size: 12px;
    line-height: 15px;
    border: none;
    border: 1px solid #05051D;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &_icon {
      padding-left: 34px;
    }

    &::placeholder {
      color: #AFAFAF;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 6px 0px $pink;
      border: 1px solid $pink;
    }
  }

  &__icon {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
  }
}