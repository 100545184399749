.FreshDrop {
    box-shadow: none;

    &__toggle {
        width: 100%;
        outline: none;
        box-shadow: none !important;
        margin: 0;
        padding: 0 !important;
        height: auto;

        .FreshInput__input {
            cursor: pointer;
            user-select: none;
            pointer-events: none;
        }
    }

    &__dropdown {
        height: auto;
        background-color: transparent;
        outline: none;
        padding: 0 !important;
        margin: 0 !important;
        background: 0 !important;
        border: none !important;
        border-radius: 0 !important;
        z-index: 4000 !important;
    }
}