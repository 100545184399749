.StoryEditorSelect {
  position: fixed;
  border: 1px solid #EB5757;
  background-color: rgba($color: #EB5757, $alpha: 0.3);
  z-index: 9999;

  &_hidden {
    visibility: hidden;
  }
}
