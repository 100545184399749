.Typography {
  &_styling {
    &_h5 {
      padding-bottom: 20px;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
    }
  }

  &_align {
    &_left {
      text-align: left;
    }

    &_center {
      text-align: center;
    }

    &_right {
      text-align: right;
    }
  }

  &_color {
    &_dark {
      color: #333333;
    }

    &_light {
      color: white;
    }
  }
}