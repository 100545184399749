.Table {
  width: 100%;
  background: #FFFFFF;
  border-radius: 8px;

  &__head {

  }

  &__headCell {
    padding: 0 16px;
    height: 40px;
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    color: #1A344B;
    vertical-align: middle;
    border-bottom: 1px solid #D9E3EB;
  }

  &__body {

  }

  &__bodyCell {
    padding: 0 16px;
    height: 48px;
    vertical-align: middle;
  }

  &__row {
    &:not(:last-child) td {
      border-bottom: 1px solid #D9E3EB;
    }
  }
}