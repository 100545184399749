@import "./src/variables.scss";

.StoryCardMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #18182E;
  height: 100%;
  padding: 35px;

  &_theme {
    &_light {
      background: map-get($fresh-theme-colors, 'grey-background');
    }
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    text-align: center;
    color: map-get($fresh-theme-colors, 'grey');
  }
}