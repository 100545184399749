@import 'src/variables.scss';

.WidgetAnalyticsAnswers {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid map-get($fresh-theme-colors, 'grey-light');

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  &__statListItem {
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__statListTitle {
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    margin-bottom: 8px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: map-get($fresh-theme-colors, 'grey');
  }

  &__statListWidgetTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: map-get($theme-colors, 'black');
    margin-bottom: 16px;
  }

  &__statListWidgetDesc {
    font-size: 14px;
    line-height: 20px;
    color: map-get($theme-colors, 'black');
    margin: 0;
  }

  &__statListItemTopContainer {
    display: flex;
    justify-content: space-between;
  }

  &__statListItemTitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: map-get($theme-colors, 'black');
    text-transform: capitalize;
    text-overflow: ellipsis;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
  }

  &__statListItemValue {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: map-get($theme-colors, 'black');
  }

  &__statListItemEmoji {
    margin-right: 8px;
  }

  &__statListItemTitleContainer {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }

  &__statListItemTopContainerLeft {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }

  &__statListItemValueAdditional {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: map-get($fresh-theme-colors, 'grey');
  }

  &__statListItemBottomContainer {
    margin-top: 2px;
  }

  &__statPercentLine {
    width: 100%;
    height: 4px;
  }

  &__statPercentLineValue {
    height: 100%;
    background: map-get($fresh-theme-colors, 'grey-light');
    border-radius: 2px;
  }
}