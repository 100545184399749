@import "./src/variables.scss";

.LocalizationTab {
  &__languages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__languagesTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');
    margin-bottom: 12px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 8px;
    }
  }
}