@import "./src/variables.scss";

.WidgetContextMenu {
  position: absolute;
  bottom: 0;
  width: 200px;

  border: 1px solid #E0E0E0;
  background-color: #FFFFFF;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 6px;

  z-index: 5;

  visibility: hidden;

  &_show {
    visibility: visible;
  }
  
  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    border: none;

    background: #FFFFFF;
    border-radius: 3px;
    padding: 0 24px;

    color: #000000;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;

    color: map-get($fresh-theme-colors, 'black');
    transition: .225s ease all;

    &:hover {
      background: map-get($fresh-theme-colors, 'pink');
      color: #FFFFFF;
    }

    &:disabled {
      color: map-get($fresh-theme-colors, 'grey');
      cursor: auto;

      &:hover {
        background: #FFFFFF;
        color: map-get($fresh-theme-colors, 'grey');
      }
    }

  }
}
