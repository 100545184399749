.CalendarTime {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-weight: 700;

  &__selected-time {
    display: flex;
    width: 100%;
    align-self: center;
    justify-content: center;
    padding-bottom: 10px;
  }
  &__selects {
    display: flex;
    overflow: hidden;
  }
}
