@import 'src/variables.scss';

.AddAppPage {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  &__container {
    width: 440px;
    background: #FFFFFF;
    border-radius: 24px;
    padding: 40px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 100%;
    }
  }
}