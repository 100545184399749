@import '/src/variables.scss';

.SettingsInput {
  display: flex;
  align-items: center;
  height: 34px;
  background: #05051D;
  border-radius: 8px;
  color: #AFAFAF;
  padding: 0 12px;
  width: 95px;

  &_sub {
    border-radius: 0 8px 8px 0;
  }

  &_grow {
    flex-grow: 1;
  }

  &_disabled &__prefix,
  &_disabled &__postfix,
  &_disabled &__input {
    opacity: 0.3;
  }

  &__prefix {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-right: 6px;
  }

  &__postfix {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    margin-left: 5px;
  }

  &__icon {
    display: flex;
    margin-right: 10px;

    & svg {
      width: 14px;
      height: 14px;
    }
  }

  &__input {
    width: 100%;
    min-width: 30px;
    height: 34px;
    border: none;
    background: none;
    color: white;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    outline: none;
    box-shadow: none;
  }

  &_withIcon+&_withPostfix {
    .SettingsInput__input {
      min-width: 20px;
    }
  }

}