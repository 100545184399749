@import 'src/variables.scss';

.NoWidgetsAccessMessage {
  margin-bottom: 12px;

  &__text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'grey');
    margin-bottom: 12px;
  }
}