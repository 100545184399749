@import './src/variables.scss';

.AppSubscriptionCard {
  display: flex;
  justify-content: space-between;


  &__title {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');
    margin-bottom: 6px;
  }

  &__price {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: map-get($fresh-theme-colors, 'black');
    margin-bottom: 0;
    text-transform: lowercase;
  }
}