@import 'src/variables.scss';

.AuthLayout {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background: #F8F7F8;
    padding: 20px 0;

    @media (max-width: map-get($grid-breakpoints, lg)) {
        padding: 0 10px;
    }

    &__header {
        display: flex;
        justify-content: center;
        margin: 20px 0;
    }

    &__title {
        text-align: center;
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        color: map-get($fresh-theme-colors, 'black');
        margin-bottom: 40px;
    }

    &__content {
        background: #FFFFFF;
        box-sizing: border-box;
        border-radius: 24px;
        padding: 40px;
        width: 440px;
        color: map-get($fresh-theme-colors, 'black');

        @media (max-width: map-get($grid-breakpoints, lg)) {
            width: 100%;
        }
    }

    &__google {
        margin-bottom: 30px;
        border: 1px solid #FFFFFF;
    }

    &__footer {
        margin: 20px 0;
        display: flex;
        justify-content: center;
    }
}