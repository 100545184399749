@import './src/variables.scss';

.AddStory {
  height: 100%;
  width: 100%;
  border: 0;
  padding: 0;
  background-color: #ffffff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;

  &_editorVariant {
    border: 2px dashed #E9E6E9;
  }

  &:hover {
    .AddStory__circle {
      background-color: map-get($fresh-theme-colors, 'pink');
    }

    .AddStory__plus-icon {
      stroke: #ffffff;
    }
  }

  &:focus {
    outline: none;
  }

  &_editorVariant:hover {
    .AddStory__plus-icon {
      stroke: none;
      fill: #ffffff;
    }
  }

  &:not(:hover) &__circle {
    display: flex;
  }

  &__addBtn {
    display: block;
    background-color: map-get($theme-colors, 'grey');
    border-radius: 50%;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }

  &__addBtnIcon {
    width: 14px;
    height: 14px;
  }

  &__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 50px;
    background-color: map-get($fresh-theme-colors, 'grey-background');
    transition: .225s all ease-in-out;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 40px;
      height: 40px;

      svg {
        width: 10px;
        height: 10px;
      }
    }
  }

  &__plus-icon {
    display: block;
    transition: .225s all ease-in-out;
  }

  &__button-group {
    display: none;
    padding: 0 42px;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
  }

  &_editorVariant:hover &__button-group {
    display: flex;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 20px 18px;
    background-color: #05051D;
    border: none;
    border-radius: 16px;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #ffffff;
    text-transform: uppercase;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);

    &:hover {
      background: #FF00D0;
    }
  }

  &__text {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'grey');
    margin: 0;
    margin-top: 14px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 10px;
      line-height: 14px;
    }
  }


  @media screen and (max-height: 701px) {
    &__button-item {
      padding: 18px 12px;
      font-size: 14px;
    }
  }

  &__btn-icon {
    stroke: #ffffff;
    margin-right: 12px;
  }

  &__abs {
    position: absolute;
  }
}