.MaterialIcon {
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  & > svg {
    width: 100%;
    height: 100%;
  }
}