.MaterialIconsPicker {
    width: 100%;
    &__button {
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: #05051D;
        border-radius: 8px;
        color: #fff;
        padding: 8px 8px;
        font-size: 12px;
        line-height: 14px;
        & svg {
            width: 18px;
            height: 18px;
        }

        &_group {
            width: 34px;
            height: 34px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 1px solid #18182E;;
        }

    }
}