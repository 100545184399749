@import 'src/variables.scss';

.LocaleAdd {
  position: relative;
  display: flex;
  flex-direction: column;

  &__btn {
    width: 320px;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 16px;
    background: #FFFFFF;
    border: 1px solid map-get($fresh-theme-colors, 'grey-light');
    box-sizing: border-box;
    border-radius: 12px;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 100%;
      padding: 10px;
      font-size: 12px;
      line-height: 14px;
    }

    &_active {
      border: 1px solid $pink;
    }
  }

  &__addIconWrapper {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    border-radius: 50px;
    background-color: $pink;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 14px;
      height: 14px;
    }
  }

  &__localesListItemIcon {
    margin-right: 16px;

    &>svg {
      width: 18px;
      height: 12px;
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      margin-right: 8px;

      &>svg {
        width: 16px;
        height: 10px;
      }
    }
  }

  &__localesListItem {
    display: flex;
    align-items: center;
    padding: 8px 16px;

    &:hover {
      text-decoration: none;
      background: map-get($fresh-theme-colors, 'grey-background');
    }
  }

  &__localesListItemTitle {
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');
    text-decoration: none;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__addIcon {
    width: 6px;
    height: 6px;
  }

  &__select {
    &_top {
      position: absolute;
      bottom: 56px;
      left: 0;
    }
  }

  &__localesList {
    width: 320px;
    height: 148px;
    box-shadow: 0px 12px 24px rgba(42, 30, 42, 0.1);
    border-radius: 12px;
    border: 1px solid map-get($fresh-theme-colors, 'grey-light');
    margin-top: 4px;

    overflow-y: auto;
    background: #FFFFFF;
  }

  &__selectModal {
    .SearchSelect__optionsItem {
      font-weight: 400;
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 100% !important;
    }
  }
}