@import "./src/variables";

.EditorStories {
  display: flex;
  flex-direction: column;
  height: 100%;

  &_skeleton {
    overflow-x: hidden;
  }

  &__storiesContainer {
    flex-grow: 1;

    &_noScroll {
      overflow: hidden;
    }

    &_noTitle {
      padding-top: 50px;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    color: #ffffff;
    margin: 0;
    max-width: 700px;
    word-break: keep-all;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
  }

  &__titleContainer {
    display: flex;
    align-items: center;
    padding: 0 24px;
    margin-bottom: 10px;
    gap: 20px;
  }

  &__addBtn {
    display: block;
    background-color: map-get($theme-colors, 'grey');
    border-radius: 50%;
    width: 48px;
    height: 48px;
  }

  &__addBtnIcon {
    width: 14px;
    height: 14px;
  }

  &__counter {
    display: block;
    background-color: map-get($theme-colors, 'grey');
    border-radius: 12px;
    padding: 8px 12px;

    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    color: #AFAFAF;
  }

  &__storiesSkeleton {
    display: flex;
    margin: 0;
    width: 100%;
    height: 100%;
    align-items: center;
  }

  &__scrolllContainer {
    display: flex;
    height: calc(100% - 53px);

    &_fullWidth {
      height: 100%;
    }
  }

  &__stories-carousel {
    height: 100% !important;

    .simplebar-content {
      display: flex;
      margin: 0;
      width: 100%;
      align-items: flex-start;
      transition: 0.3s opacity ease-in-out;
    }

    &_pending {
      .simplebar-content {
        opacity: 0.5;
        transition: 0.3s opacity ease-in-out;
        pointer-events: none;
      }
    }
  }

  &__carousel-item {
    display: flex;
    align-items: center;
    position: relative;
    list-style: none;
    height: 100%;

    margin-right: 24px;

    &:first-child {
      margin-left: 24px;
    }

    &:last-child {
      padding-right: 24px;
    }
  }

  &__toolbar-wrapper {
    margin-top: 12px;
  }

  &__widget-popup-wrapper {
    position: absolute;
    left: 0;
    top: 0;
  }

  &__card {
    position: relative;
    padding: 0;
    width: 360px;
    height: 640px;
    border-radius: 8px;
    overflow: hidden;
    background: transparent;
    border: none;

    &_selected {
      box-shadow: none;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &_noBorderRadius {
      border-radius: 0;
    }

    &_skeleton {
      line-height: 0;
    }
  }
}