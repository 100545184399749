$icon-space: 30px;
$horizontal-padding: 12px;

.OldInput {
  background: #05051D;
  border-radius: 8px;
  border: 1px solid #05051D;
  box-sizing: border-box;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  outline: none !important;
  padding: 10px 6px 10px 6px;
  width: 100%;
  margin: 0;

  &_light {
    background: #FFFFFF;
    color: #05051D;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  &--withLeftIcon {
    padding-left: 30px !important;
  }
  &--withRightIcon {
    padding-right: 30px !important;
  }

  &:focus {
    box-shadow: 0 0 6px 0 #ff80e8 !important;
    border: 1px solid #ff80e8 !important;
  }

  &__wrapper {
    position: relative;
    width: 100%;
  }
  
  &__icon {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    &--withLeftIcon {
      left: $horizontal-padding;
    }
    &--withRightIcon {
      right: $horizontal-padding;
    }
  }
}