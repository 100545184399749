@import 'src/variables.scss';

.MauCounter {
  display: flex;
  gap: 44px;
  align-items: center;

  &__container {
    width: 322px;
  }

  &__mau {
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 5px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__progress {
    width: 100%;
    border-radius: 4px;
    height: 8px;
    background: map-get($fresh-theme-colors, 'grey-light');
    margin-bottom: 12px;
    overflow: hidden;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      height: 6px;
      margin-bottom: 8px;
    }
  }

  &__progressBar {
    height: 100%;
    border-radius: 4px;
    background: map-get($fresh-theme-colors, 'pink');
  }

  &__month {
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    margin: 0;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
    }
  }
}