@import '/src/variables.scss';

.StoryObjectComponent {
  position: absolute;
  z-index: auto;

  &__wrapper {
    width: 100%;
    height: 100%;
    pointer-events: none;
    position: relative;

    &_transparent {
      pointer-events: all;
    }
  }

  &__outline {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #ff00d0;
    pointer-events: none;
  }

  &_dragging {
    cursor: move !important;
  }

  &_isSelected {
    cursor: move !important;
  }

  &_isSelectedTop {
    z-index: 3700 !important;
  }

  &__moveable {
    display: none !important;

    --moveable-color: #ff00d0 !important;

    &_isSelected {
      display: block !important;
    }

    .moveable-control {
      width: 7px !important;
      height: 7px !important;
      margin-top: -3.5px !important;
      margin-left: -3.5px !important;
      border-radius: 2px !important;
      background: #fff !important;
      border: 1px solid #ff00d0 !important;
    }
  }

  &__handle {
    height: 100%;
  }

  &_isSelected &__resizeControl {
    position: absolute;
    width: 7px;
    height: 7px;
    border: 1px solid #FF00D0;
    border-radius: 2px;
    background: #ffffff;

    &_lt {
      left: -4px;
      top: -4px;
      cursor: nw-resize;
    }

    &_rt {
      right: -4px;
      top: -4px;
      cursor: ne-resize;
    }

    &_rb {
      right: -4px;
      bottom: -4px;
      cursor: se-resize;
    }

    &_lb {
      left: -4px;
      bottom: -4px;
      cursor: sw-resize;
    }
  }

  &_isSelected &__rotateControl {
    position: absolute;
    width: 20px;
    height: 20px;

    &_lt {
      left: -17px;
      top: -17px;
      cursor: url(rotate-cursor.svg) 14 14, default;
    }

    &_rt {
      right: -17px;
      top: -17px;
      cursor: url(rotate-cursor.svg) 14 14, default;
    }

    &_rb {
      right: -17px;
      bottom: -17px;
      cursor: url(rotate-cursor.svg) 14 14, default;
    }

    &_lb {
      left: -17px;
      bottom: -17px;
      cursor: url(rotate-cursor.svg) 14 14, default;
    }
  }
}