@import './src/variables.scss';

.FreshCheckbox {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;

  &_disabled {
    cursor: auto;
  }

  &__checkbox {
    opacity: 0;
    position: absolute;
    cursor: pointer;

    &_disabled {
      cursor: auto;
    }
  }

  &__customCheckbox {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    width: 20px;
    height: 20px;
    background: map-get($fresh-theme-colors, 'grey-light');
    cursor: pointer;

    &_disabled {
      cursor: auto;
    }

    &_type {
      &_round {
        width: 18px;
        height: 18px;
        background: rgba(24, 24, 46, 0.5);
        border: 1px solid #FFFFFF;
        border-radius: 50%;
      }
    }

    &_invalid {
      border: 1px solid map-get($fresh-theme-colors, 'red');
    }

    &_checked {
      background: map-get($fresh-theme-colors, 'pink');

      &.FreshCheckbox__customCheckbox_type_round {
        background: #00CC66;
        border: none;
      }
    }

    &>svg {
      font-size: 14px;
    }
  }

  &__label {
    margin-left: 14px;
    font-size: 14px;
    line-height: 20px;

    color: map-get($fresh-theme-colors, 'black');

    a {
      color: map-get($fresh-theme-colors, 'pink');
      text-decoration: none;

      &:hover {
        color: map-get($fresh-theme-colors, 'pink');
        text-decoration: none;
      }
    }
  }
}