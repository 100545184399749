@import 'src/variables.scss';

.CreateGroupContainer {
  &__dropArea {
    position: relative;
    width: 96px !important;
    height: 96px !important;
    margin: 0 auto;
    margin-bottom: 40px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 83px !important;
      height: 83px !important;
    }
  }

  &__labelContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &__labelInfo {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
    color: map-get($fresh-theme-colors, 'grey');
    transition: 0.3s ease all;

    &_red {
      color: map-get($fresh-theme-colors, 'red');
    }
  }

  &__lableTitle {
    margin: 0;
  }

  &__btn {
    margin-top: 40px;
  }
}