@import './src/variables.scss';

.FreshDropInput {
  position: relative;
  display: flex;
  width: 100%;
  padding: 14px 16px;
  border-radius: 12px;
  font-size: 14px;
  line-height: 20px;
  color: map-get($fresh-theme-colors, 'white');
  background: #FFFFFF;
  border: 1px solid #E9E6E9;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  padding-right: 50px;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding: 10px;
    font-size: 12px;
    line-height: 16px;
  }

  &__icon {
    position: absolute;
    right: 16px;
    width: 20px;
    top: 50%;
    transform: translateY(-50%);

    &_right {
      left: auto;
      right: 16px;
    }
  }

}