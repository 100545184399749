@import './src/variables.scss';

.MockupViewContainer {
  display: flex;
  justify-content: center;
  width: 220px;
  border: 1px solid #E9E6E9;
  border-radius: 12px;
  padding: 5px;
  transition: .225s all ease-in-out;
  margin-bottom: 6px;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    width: 100%;
  }

  &_active {
    border: 1px solid #FD19CC;
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 4px rgba(253, 25, 204, 0.15);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 16px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      margin-right: 0;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    color: #2A1E2A;
    transition: .225s all ease-in-out;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      margin-bottom: 0;
    }

    &_active {
      color: #FD19CC;
    }
  }
}