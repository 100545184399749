@import '/src/variables.scss';

.Modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 10000;

  &_isOpen {
    display: block;
  }

  &__wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
    background: rgba(24, 24, 46, 0.6);
    color: white;

    &_isOpen {
      display: flex;
    }

    &_position {
      &_right {
        align-items: flex-start;
        justify-content: flex-end;
        padding: 10px;
      }
    }
  }

  &__dialog {
    display: flex;
    flex-direction: column;
    padding: 30px;
    background: #18182E;
    position: relative;
    // border: 1px solid #05051D;
    box-sizing: border-box;
    border-radius: 8px;

    &_size {
      &_sm {
        width: 426px;
      }

      &_md {
        width: 442px;
        padding: 16px 20px;
      }

      &_lg {
        width: 790px;
      }
    }

    &_template {
      &_light {
        background: $blue;
        color: map-get($theme-colors, 'black');
      }
    }
  }

  &__title {
    display: block;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: white;

    &_centered {
      text-align: center;
    }
  }
}
