.ImageLoaderWrapper {
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;

  &:focus, &:hover {
    border: none;
    outline: none;
  }

  &__fileInput {
    display: none;
  }
}
