@import 'src/variables.scss';

.AddGroupPage {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    gap: 12px;
  }

  &__creationTabs {
    width: 600px;
    background: #FFFFFF;
    border-radius: 24px;
    padding: 40px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 100%;
      padding: 20px;
    }
  }

  &__creationTabsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      grid-template-columns: 1fr;
    }
  }

  &__creationTabItemTitle {
    display: flex;
    gap: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'pink');
    margin-bottom: 10px;
    align-items: center;
  }

  &__creationTabItemDescription {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'grey');
    margin: 0;
    text-align: left;
  }

  &__creationTabItem {
    cursor: pointer;
    padding: 16px;
    border-radius: 16px;
    border: 1px solid map-get($fresh-theme-colors, 'grey-light');
    transition: .225s ease all;
    display: flex;
    flex-direction: column;

    &:hover {
      border-color: map-get($fresh-theme-colors, 'pink');
    }
  }

  &__container {
    width: 440px;
    background: #FFFFFF;
    border-radius: 24px;
    padding: 40px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 100%;
    }
  }

  &__tabs {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 440px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 100%;
      padding: 0;
    }
  }

  &__tab {
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
    border-radius: 16px;
    background: #FFFFFF;
    border: 1px solid transparent;
    transition: .225s ease all;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: #A49BA4;


    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      gap: 8px;
      padding: 8px;
      border-radius: 10px;
    }

    &_active {
      border-color: map-get($fresh-theme-colors, 'pink');
      color: map-get($fresh-theme-colors, 'pink');
    }
  }

  &__icon {
    width: 24px;
    height: 24px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 18px;
      height: 18px;
    }

    &_filled {
      fill: map-get($fresh-theme-colors, 'grey');

      &.AddGroupPage__icon_active {
        fill: map-get($fresh-theme-colors, 'pink');
      }
    }

    &_stroked {
      stroke: map-get($fresh-theme-colors, 'grey');

      &.AddGroupPage__icon_active {
        stroke: map-get($fresh-theme-colors, 'pink');
      }
    }
  }
}