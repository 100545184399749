@import "./src/variables.scss";

.SettingsAlign {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;

    &:hover {
      .SettingsAlign__icon {
        fill: map-get($fresh-theme-colors, 'pink');
        transition: .225s ease all;
      }
    }
  }

  &__icon {
    fill: map-get($fresh-theme-colors, 'grey');
  }
}