.ConfirmEmailModal {
  &__img {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 auto;
  }

  &__title {
    font-size: 1rem;
    line-height: 1.5rem;
    color: #fff;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }

  &__btnContaier {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
  }
}