@import 'src/variables.scss';

.AnalyticsIndicator {
  padding: 16px;
  border-radius: 20px;
  border: 1px solid map-get($fresh-theme-colors, 'grey-light');
  width: 170px;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    width: 100%;
  }

  &__title {
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');
    margin: 0;
    font-feature-settings: 'liga' off, 'clig' off;
    margin-bottom: 24px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 12px;
    }
  }

  &__value {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: map-get($fresh-theme-colors, 'black');
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 20px;
      line-height: 24px;
    }
  }
}