@import '/src/variables.scss';

.SettingsTextAlign {
  display: flex;

  &__item {
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    margin-right: 7px;
    background: transparent;
    border-radius: 8px;

    &_active {
      background: map-get($theme-colors, 'black');

    }

    &:last-child {
      margin-right: 0;
    }
  }
}