@import 'src/variables.scss';

.CountdownTimer {
  display: flex;
  align-items: center;
  gap: 8px;

  &__icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  &__text {
    font-size: 14px;
    font-weight: 600;
    color: map-get($theme-colors, 'black');
  }
}