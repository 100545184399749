@import './src/variables.scss';

.StoryShareModal {
  &__desc {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 24px;
    text-align: center;
    color: map-get($theme-colors, 'grey');

    &_white {
      color: #FFFFFF;
    }
  }

  &__link {
    margin-bottom: 24px;
  }

  &__btnContaier {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 40px;
  }

  &__btnIcon {
    stroke: #FFFFFF;
  }

  &__qrContainer {
    display: flex;
    justify-content: center;
  }

  &__qrWrapper {
    padding: 10px;
    background: #FFFFFF;
  }
}