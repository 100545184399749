@import "/src/variables.scss";

.GroupGeneralAnalytics {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;

  &__container {
    display: flex;
    border-right: 1px solid map-get($fresh-theme-colors, 'grey-light');
    padding: 0 44px;

    &:last-child {
      border: none;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
    min-width: 120px;
  }

  &__itemTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'grey');
    margin-bottom: 4px;
  }

  &__itemValue {
    margin: 0;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    color: map-get($fresh-theme-colors, 'black');

  }
}