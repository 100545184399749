@import 'src/variables.scss';

.UsageTab {
  &__title {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 12px;
    color: map-get($fresh-theme-colors, 'black');

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 8px;
    }
  }

  &__desc {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: map-get($fresh-theme-colors, 'black');
    margin: 0;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 28px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__chartContainer {
    width: 100%;
  }

  &__chart {
    width: 100%;
    border-radius: 20px;
    border: 1px solid map-get($fresh-theme-colors, 'grey-light');
    padding: 40px;
  }

  &__chartTitle {
    font-size: 22px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 50px;
  }

  &__pieChart {
    border-radius: 20px;
    border: 1px solid map-get($fresh-theme-colors, 'grey-light');
    padding: 20px;
    max-width: 520px;
  }

  &__pieChartTitle {
    font-size: 22px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 24px;
  }
}