@import './src/variables.scss';

.BackgroundSelector {
  width: 100%;

  &__tab {
    display: none;

    &_current {
      display: block;
    }
  }

  &__header {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }

  &__tabControl {
    height: 32px;
    width: 32px;
    padding: 0;
    border-radius: 50%;
    margin: 0 6px;
    border: none;
    color: transparent;

    &_type {
      &_color {
        background: #474764;

        &.BackgroundSelector__tabControl_current {
          background: map-get($fresh-theme-colors, 'pink');
        }
      }

      &_gradient {
        background: linear-gradient(180deg, #FFFFFF 0%, #474764 100%);
        opacity: 0.5;

        &.BackgroundSelector__tabControl_current {
          background: linear-gradient(180deg, #FFFFFF 0%, map-get($fresh-theme-colors, 'pink') 100%);
          opacity: 1;
        }
      }

      &_image {
        display: flex;
        align-items: center;
        justify-content: center;
        color: inherit;
        background: transparent;
      }
    }
  }

  &__tabs {
    &_grey {

      .MediaLoader__fileDrop,
      .Picker__input {
        background-color: map-get($theme-colors, 'grey');
      }
    }
  }
}