@import './src/variables.scss';

.StoriesTemplatesItemContainer {
  background: #FFFFFF;
  border-radius: 16px;
  padding: 24px;
  padding-bottom: 40px;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-bottom: 24px;
  }

  &__headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');
  }

  &__btnsContainer {
    display: flex;
    align-items: center;
    gap: 10px;

    .FreshButton {
      height: 32px;
    }
  }

  &__btnIcon {
    width: 15px;
    height: 15px;
    transition: .225s all ease-in-out;
  }

  &__desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'grey');
    text-align: center;
    margin: 0;
  }

  &__storiesContainer {
    display: flex;
    width: 100%;
    gap: 17px;
    flex-wrap: nowrap;
    overflow-x: auto;
    @include use-light-scroll;
    padding-bottom: 24px;
  }

  &__titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4px;
  }

  &__empty {
    width: 174px;
    height: 309px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    overflow: hidden;
    border: 1px dashed #D2CDD1;
    text-align: center;
    color: #A49BA4;
    font-size: 14px;
  }

  &__btnIcon {
    fill: #FFFFFF;
    width: 18px;
    height: 18px;
  }

  &__btn {
    height: auto !important;
  }

  //   &__btn {
  //   width: 240px;

  //   @media (max-width: map-get($grid-breakpoints, lg)) {
  //     width: 100%;
  //     padding: 10px;
  //     font-size: 12px;
  //   }
  // }
}