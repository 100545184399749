@import "./src/variables";

$widgets-sidebar-w: 278;
$settings-sidebar-w: 240;
$header-h: 104;

.EditorLayout {
  display: flex;
  min-height: calc(100vh - 24px);
  margin-right: auto;
  margin-left: auto;
  align-items: stretch;
  flex-wrap: nowrap;
  flex-direction: column;
  overflow: hidden;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  background-color: map-get($theme-colors, 'black');

  &__mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: map-get($theme-colors, 'black');
    width: 100%;
    height: 100vh;
    padding: 20px;
  }

  &__mobileButton {
    width: auto;
  }

  &__header {
    margin-bottom: 24px;
  }

  &__mobileText {
    color: #FFFFFF;
    font-size: 14px;
    text-align: center;
  }

  &__main {
    display: grid;
    grid-template-columns: max-content 1fr;
    height: calc(100vh - #{$header-h}px);
    width: 100%;
    padding-right: 240px;
    position: relative;


    &_noStories {
      padding-right: 0;
    }
  }

  &__sidebar {
    opacity: 1;

    &_noStories {
      width: 0;
      opacity: 0;
    }
  }

  &__settings {
    width: 240px;
  }

  &__settingsContainer {
    width: 240px;
    height: calc(100vh - 104px);
    position: absolute !important;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end !important;
    z-index: 500;

    &_pickerOpen {
      width: 500px;
    }

    &_noStories {
      width: 0;
    }
  }

  &__settingsContentWrapper {
    border-radius: 16px;

    .simplebar-content {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__content {
    background-color: map-get($theme-colors, 'black');

    overflow-x: hidden;
    overflow-y: hidden;

    @include use-dark-scroll;
  }
}