@import './src/variables.scss';

.StoriesTemplateCard {
  background: #FFFFFF;
  border: 1px solid map-get($fresh-theme-colors, 'grey-light');
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  transition: .225s ease all;
  max-width: 346px;

  &_choosen {
    border: 1px solid map-get($fresh-theme-colors, 'pink');
    background: map-get($fresh-theme-colors, 'grey-background');
  }

  &__preview {
    display: flex;
    gap: 12px;
    margin-bottom: 12px;
    cursor: pointer;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__previewItem {
    width: 96px;
    height: 170px;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    font-size: 0;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 83px;
      height: 150px;
    }
  }

  &__previewCheckbox {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 1;
    width: 18px;
    height: 18px;
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');
    margin-bottom: 4px;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      color: map-get($fresh-theme-colors, 'black');
    }
  }

  &__desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'grey');
    margin: 0;
    margin-bottom: 12px;
  }

  &__btnContainer {
    margin-top: 12px;
    display: flex;
    gap: 12px;
  }

  &__btn {
    font-size: 12px;
    line-height: 18px;
    padding: 10px 14px;
  }

  &__btnIcon {
    stroke: #ffffff;
  }

  &__actionsContainer {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}