@import '/src/variables.scss';

.FreshCalendarDayView {
  align-items: center;
  background-color: transparent;
  font-size: 14px;
  line-height: 20px;
  width: 32px;
  height: 32px;
  justify-content: center;
  outline: none;
  padding: 0;
  position: relative;

  border: 1px solid #E9E6E9;
  border-radius: 8px;
  color: map-get($fresh-theme-colors, 'black');



  margin: 2px;

  &:hover {
    background: map-get($fresh-theme-colors, 'grey-background');
    cursor: pointer;
  }

  &_theme {
    &_dark {
      color: #FFFFFF;

      &:hover {
        color: map-get($fresh-theme-colors, 'black');
      }
    }
  }

  &_select {
    background: rgba(253, 25, 204, 0.1);
    border: 1px solid rgba(253, 25, 204, 0.2);

    &:hover {
      background: map-get($fresh-theme-colors, 'pink');
      color: #ffffff;
    }

  }

  &_first,
  &_last {
    font-weight: 600;

    color: #FFFFFF;
    border-color: map-get($fresh-theme-colors, 'pink');
    background: map-get($fresh-theme-colors, 'pink');

    &:hover {
      background: map-get($fresh-theme-colors, 'pink');
      color: #FFFFFF;
    }
  }

}