@import 'src/variables.scss';

.GroupViewContainer {
  display: flex;
  border: 1px solid #E9E6E9;
  border-radius: 12px;
  padding: 10px;
  margin-right: 16px;
  margin-bottom: 16px;
  transition: .225s all ease-in-out;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    width: 100%;
    justify-content: center;
    padding: 8px;
    margin-right: 0;
  }

  &_active {
    border: 1px solid #FD19CC;
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 4px rgba(253, 25, 204, 0.15);
  }

  &:last-child {
    margin-right: 0;
  }
}