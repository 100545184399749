.Tab {
  display: flex;
  align-items: center;
  padding: 0 16px;
  width: 160px;
  height: 40px;
  background-color: #ff00d0;
  border-radius: 8px 8px 0px 0px;
  border: none;
  opacity: 0.5;
  color: #fff;

  &--active {
    opacity: 1;
  }
}