@import "./src/variables";

.LocaleSelector {
  position: relative;

  &__icon {
    display: flex;
    align-items: center;
    margin-right: 8px;

    &>svg {
      width: 18px;
      height: 12px;

      @media (max-width: map-get($grid-breakpoints, lg)) {
        width: 16px;
        height: 10px;
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: space-between;
    height: 34px;
    align-items: center;
    padding: 0 12px;
    border-radius: 8px;
    background-color: map-get($theme-colors, 'black');
    border: none;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      padding: 0 8px;
    }

    &_theme {
      &_light {
        height: 38px;
        border-radius: 12px;
        border: 1px solid map-get($fresh-theme-colors, 'pink');
        background-color: #ffffff;

        @media (max-width: map-get($grid-breakpoints, lg)) {
          height: 28px;
          border-radius: 8px;
        }
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    margin: 0;

    &_theme {
      &_light {
        color: map-get($theme-colors, 'black');
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        @media (max-width: map-get($grid-breakpoints, lg)) {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }

  &__chevron {
    margin-right: 4px;
    margin-left: 10px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      margin-left: 4px;
      margin-right: 0;
      width: 16px;
      height: 16px;
    }
  }

  &__menu {
    width: 232px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: #FFFFFF;
    ;
    box-shadow: 0px 12px 24px rgba(42, 30, 42, 0.1);
    border-radius: 12px;
    overflow: hidden;
  }

  &__menuManage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    cursor: pointer;
    border-bottom: 1px solid #E9E6E9;


    &:hover {
      text-decoration: none;
    }

  }

  &__menuExtLink {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    color: map-get($fresh-theme-colors, 'pink');
  }

  &__menuExtIcon {
    width: 16px;
    height: 18px;
  }

  &__menuContent {
    max-height: 144px;
    overflow-y: auto;
  }

  &__menuItem {
    display: flex;
    align-items: center;
    padding: 8px 13px;

    &:hover {
      background: map-get($fresh-theme-colors, 'grey-background');
      cursor: pointer;
    }
  }

  &__menuItemTitle {
    display: flex;
    align-items: center;
    gap: 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');
    margin: 0;
  }

  &__checkIcon {
    width: 18px;
    height: 18px;
    margin-left: auto;
  }

  &__warningIcon {
    fill: map-get($fresh-theme-colors, 'pink');
  }
}