@import './src/variables.scss';

.StoriesTemplatesCatalogContainer {
  background: #FFFFFF;
  border-radius: 16px;
  padding: 24px;
  padding-bottom: 40px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 36px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
      margin-bottom: 24px;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    margin: 0;
    color: map-get($fresh-theme-colors, 'black');

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__btnIcon {
    fill: #FFFFFF;
    width: 18px;
    height: 18px;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  &__nav {
    display: flex;
    align-items: center;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 100%;
    }
  }

  &__navItem {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'grey');
    transition: 0.2s ease all;
    padding: 0 24px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      padding: 0 12px;
      font-size: 12px;
    }

    &_disabled {
      cursor: auto;
      pointer-events: none;
    }

    &_active {
      color: map-get($fresh-theme-colors, 'black');

      & .StoriesTemplatesCatalogContainer__navItemIcon {
        stroke: map-get($fresh-theme-colors, 'black');

        &_filled {
          stroke: none;
          fill: map-get($fresh-theme-colors, 'black');
        }
      }
    }

    &:first-child {
      border-right: 1px solid map-get($fresh-theme-colors, 'grey-light');
    }

    &:hover {
      color: map-get($fresh-theme-colors, 'black');
      text-decoration: none;
    }
  }


  &__navItemIcon {
    width: 24px;
    height: auto;
    margin-right: 10px;
    stroke: map-get($fresh-theme-colors, 'grey');
    transition: 0.2s ease all;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 20px;
      margin-right: 8px;
    }

    &_filled {
      stroke: none;
      fill: map-get($fresh-theme-colors, 'grey');
    }
  }

  &__btnsContainer {
    display: flex;
    align-content: center;
    gap: 16px
  }

  &__empty {
    width: 174px;
    height: 309px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    overflow: hidden;
    border: 1px dashed #D2CDD1;
    text-align: center;
    color: #A49BA4;
    font-size: 14px;
  }
}