@import 'src/variables.scss';

.FreshDropArea {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    width: 64px;
    height: 64px;
  }

  &_disabled {
    cursor: auto !important;
  }

  &::after {
    content: "";
    opacity: 0;
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba($color: #000000, $alpha: 0.7);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    transition: .225s ease all;
  }

  &__uploadIcon {
    position: absolute;
    z-index: 2;
    opacity: 0;
    transition: .225s ease all;
  }

  &:not(.FreshDropArea_noHover) {
    &:hover {
      &::after {
        opacity: 1;
      }

      .FreshDropArea__icon {
        stroke: map-get($fresh-theme-colors, 'pink');
      }

      .FreshDropArea__uploadIcon {
        opacity: 1;
        width: 18px;
        height: 18px;
      }
    }
  }

  &_noDefultImage {
    &::after {
      opacity: 1;
      width: 100%;
      height: 100%;
      border-radius: 0;
    }

    .FreshDropArea__icon {
      stroke: map-get($fresh-theme-colors, 'pink');
    }

    .FreshDropArea__uploadIcon {
      opacity: 1;
      width: 50%;
      height: 50%;
    }
  }

  &_border {
    border: 1px dashed #D2CDD1;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
  }

  &__icon {
    width: 40px;
    height: auto;
    transition: .225s ease all;
  }
}