@import '/src/variables.scss';

.AnalyticsInteractionsTab {
  &__controls {
    display: flex;
    overflow-x: auto;
    margin-bottom: 40px;
    gap: 12px;
    @include use-light-scroll;
  }

  &__controlItem {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 12px;
    color: map-get($fresh-theme-colors, 'black');
    text-decoration: none;
    min-height: 48px;
    padding: 14px 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    border: 1px solid #E9E6E9;
    transition: .2s border ease-in;
    text-decoration: none;
    flex-shrink: 0;

    &:focus {
      color: #fff;
    }

    &:hover {
      color: map-get($fresh-theme-colors, 'black');
      text-decoration: none;
      background: map-get($fresh-theme-colors, 'pink');
      cursor: pointer;
      border: 1px solid transparent;
      color: #fff;
    }

    &_background {
      &_pink {
        background: map-get($fresh-theme-colors, 'pink');
        cursor: pointer;
        border: 1px solid transparent;

        color: #fff;

        &:hover {
          color: #fff;
        }
      }
    }


  }

  &__groupImg {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 12px;
    object-fit: cover;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  }

  &__groupTitle {
    margin: 0;
    font-size: 12px;
  }

  &__stories {
    display: flex;
    overflow-x: auto;
    @include use-light-scroll;
  }

  &__story {
    width: 260px;
    height: 465px;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
  }

  &__storyScope {
    transform: scale(0.668) translate(-96px, -171px);
  }

  &__storyStat {
    width: 218px;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 8px;
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    padding: 16px;
  }

  &__storyStatGeneral {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &__storyStatGeneralItem {
    display: flex;
    flex-direction: column;
    align-items: center;


    &:first-child {
      border-right: 1px solid #E9E6E9;
    }
  }

  &__storyStatGeneralItemTitle {
    color: map-get($fresh-theme-colors, 'grey');
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

  }

  &__storyStatGeneralItemNumber {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin: 0;

    color: map-get($fresh-theme-colors, 'black');
  }

  &__storyStatGeneralItemDesc {
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    color: map-get($fresh-theme-colors, 'black');
  }

  &__storyStatButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    opacity: 0;
    height: 0;
    margin-top: 16px;
  }

  &__storyStatButton {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    background: #fff;
    box-shadow: 0px 12px 24px rgba(42, 30, 42, 0.1);
    border-radius: 12px;
    min-width: 186px;
    padding: 14px;
    transition: .225s all ease-in-out;


    &:hover {
      background: map-get($fresh-theme-colors, 'pink');
      color: #fff;

      .AnalyticsInteractionsTab__storyStatButtonText {
        color: #fff;
      }

      .AnalyticsInteractionsTab__storyStatButtonIcon {
        stroke: #fff;
      }

    }
  }

  &__storyStatButtonIcon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    transition: .225s all ease-in-out;
  }

  &__storyStatLoadIcon {
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }


  &__storyStatButtonText {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');

    transition: .225s all ease-in-out;
  }

  &__storyItem {
    &:hover {
      .AnalyticsInteractionsTab__storyStatButtonContainer {
        opacity: 1;
        height: auto;
      }
    }
  }

  &__emptyStory {
    position: relative;
    width: 234px;
    height: 416px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    overflow: hidden;
    border: 1px dashed #D2CDD1;
  }

  &__emptyStoryTitle {
    color: map-get($fresh-theme-colors, 'grey');
    text-align: center;
  }
}