@import 'src/variables.scss';

.SwitchGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__label {
    color: #AFAFAF;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    margin: 0;
  }
}