@import "./src/variables.scss";

.CodeSample {
  border-radius: 12px;
  overflow: hidden;
  background-color: map-get($fresh-theme-colors, 'grey-background');
  max-width: 851px;
  position: relative;

  &__container {
    width: 100%;
    padding: 0 12px;
    overflow-x: auto;

    button {
      display: none !important;
    }
  }

  &__copyButtonContainer {
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__copyButton {
    background-color: map-get($fresh-theme-colors, 'grey-background');
    border-radius: 4px;
    cursor: pointer;
    transition: 0.225s ease all;

    &:hover {
      background-color: #EDEDED;
    }
  }

  &__copyMessage {
    padding: 4px 8px;
    background-color: #EDEDED;
    border-radius: 4px;
    font-size: 12px;
    color: map-get($fresh-theme-colors, 'black');
  }
}