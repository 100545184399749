.SettingsSelect {
  position: relative;
  width: 100%;

  &__btn {
    display: flex;
    align-items: center;
    width: 100%;
    height: 34px;
    padding: 0 12px;
    background: #05051D;
    border-radius: 8px;
    border: none;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    text-align: left;
  }

  &__chevron {
    margin-left: auto;
  }

  &__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__menu {
    width: 100%;
    z-index: 1000;
    display: none;
    position: absolute;
    top: 40px;
    left: 0;
    flex-direction: column;
    border-radius: 8px;
    background-color: #05051d;
    border: 1px solid #05051d;
    box-sizing: border-box;
    overflow-y: auto;

    &_open {
      display: flex;
    }
  }

  &__option {
    width: 100%;
    padding: 12px;
    border: none;
    text-align: left;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 12px;
    line-height: 15px;
    display: block;
    text-decoration: none;
    background: none;

    &_current, &:hover {
      background: #FF00D0;
    }
  }
}