@import "./src/variables.scss";

.SetupPlatformSelect {
  max-width: 330px;
  margin-bottom: 24px;

  &__drop {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 12px;
    border: 1px solid map-get($fresh-theme-colors, 'grey-light');
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }

  &__dropItem {
    font-size: 14px;
    line-height: 46px;
    cursor: pointer;
    padding: 0 16px;
    transition: 0.2s ease all;

    &:hover {
      background: map-get($fresh-theme-colors, 'grey-light');
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      line-height: 40px;
    }
  }

  .FreshDrop__dropdown {
    width: 100%;
  }
}