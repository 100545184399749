@import 'src/variables.scss';

.ChooseTemplatePage {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    gap: 12px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1116px;
    min-height: 92px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      flex-direction: column;
      gap: 16px;
    }
  }

  &__btn {
    width: 240px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 100%;
      padding: 10px;
      font-size: 12px;
    }
  }

  &__groupContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    margin-right: 19px;
  }

  &__chosenStories {
    display: flex;
    width: 100%;
    gap: 10px;
    padding: 12px;
    border-radius: 16px;
    border: 1px solid map-get($fresh-theme-colors, 'grey-light');
    overflow-x: auto;
  }

  &__chosenStory {
    position: relative;
    width: 38px;
    height: 66px;
  }

  &__chosenStoryPreview {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;
  }

  &__altBtn {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'grey');
    transition: .225s ease all;

    &:hover {
      color: map-get($fresh-theme-colors, 'pink');
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
    }
  }

  &__btnsContainer {
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: center;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 100%;
    }
  }

  &__chosenStoryRemoveBtn {
    position: absolute;
    top: -6px;
    right: -6px;
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    padding: 0;
  }

  &__container {
    width: 100%;
    max-width: 1116px;
    background: #FFFFFF;
    border-radius: 16px;
    margin-right: 24px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      margin-right: 0;
    }
  }
}