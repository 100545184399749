@import 'src/variables.scss';

.FreshOutput {
  color: map-get($fresh-theme-colors, 'black');
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  border: 1px solid #E9E6E9;
  background: #F8F7F8;
  border-radius: 12px;
  padding: 14px 16px;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding: 10px;
    font-size: 12px;
    line-height: 14px;
  }

  &_transparent {
    background: transparent;
  }

  &_copy {
    padding-right: 36px;
  }

  &__wrapper {
    width: 100%;
    position: relative;
  }

  &__copyBtn {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  &__copyText {
    font-size: 14px;
    margin: 0;
    position: absolute;
    right: -10px;
    transform: translateX(100%) translateY(-50%);
    top: 50%;
    color: map-get($fresh-theme-colors, 'black');
  }

  &__container {
    position: relative;
  }
}