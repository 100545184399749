@import "./src/variables.scss";

.FilterControlGroup {
  display: flex;
  background: transparent;

  &__btn {
    border: none;
    background: transparent;
    font-weight: bold;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'grey');
    transition: .225s all ease-in-out;
    padding: 0 24px;
    border-right: 1px solid #E9E6E9;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      padding: 0 10px;
      font-size: 10px;
    }

    &:last-child {
      border-right: none;
    }

    &:hover {
      color: map-get($fresh-theme-colors, 'black');

      svg {
        fill: map-get($fresh-theme-colors, 'black');
      }

    }

    &_current {
      color: map-get($fresh-theme-colors, 'black');

      svg {
        fill: map-get($fresh-theme-colors, 'black');
      }
    }

    &_commonColor {
      &_pink {
        &:hover {
          color: map-get($fresh-theme-colors, 'pink');

          svg {
            fill: map-get($fresh-theme-colors, 'pink');
          }
        }
      }
    }

    &_color {
      &_pink {
        color: map-get($fresh-theme-colors, 'pink');
      }
    }

    &_disabled {
      cursor: auto !important;
      color: map-get($fresh-theme-colors, 'grey-light');

      &:hover {
        color: map-get($fresh-theme-colors, 'grey-light');
      }
    }
  }

  &__icon {
    svg {
      transition: .225s all ease-in-out;
    }

    &_color {
      &_pink {
        svg {
          fill: map-get($fresh-theme-colors, 'pink');
        }
      }
    }
  }
}

.FilterControlGroup__btn_commonColor_pink.FilterControlGroup__btn_current {
  color: map-get($fresh-theme-colors, 'pink');

  svg {
    fill: map-get($fresh-theme-colors, 'pink');
  }
}