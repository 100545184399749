@import 'src/variables.scss';

.MonthPicker {
  display: flex;
  align-items: center;
  padding: 14px;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid map-get($fresh-theme-colors, 'grey-light');

  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding: 4px;
    border-radius: 8px;
    gap: 10px;
  }

  &__arrow {
    &_prev {
      transform: rotate(90deg);
    }

    &_next {
      transform: rotate(-90deg);
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 16px;
      height: 16px;
    }
  }

  &__month {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    min-width: 138px;
    text-align: center;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      min-width: 80px;
      font-size: 12px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    padding: 0;

  }
}