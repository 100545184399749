@import 'src/variables.scss';

.EditorModeToggle {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid map-get($theme-colors, 'black');

  &__btn {
    display: flex;
    align-items: center;
    height: 24px;
    justify-content: center;
    flex-grow: 1;
    color: #A49BA4;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.05em;
    transition: 0.3s ease all;

    &:first-child {
      border-right: 1px solid map-get($theme-colors, 'black');
    }

    &_active {
      color: #ffffff;
    }
  }
}