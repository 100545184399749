@import './src/variables.scss';

.PlansCard {
  width: 280px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px 0px rgba(0, 0, 0, 0.10);
  padding: 24px;
  position: relative;

  &_disabled {
    pointer-events: none;

    &::after {
      content: "";
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
    }
  }

  &__featureIcon {
    width: 12px;
    height: 10px;
    margin-right: 14px;
    stroke: #27AE60;
  }

  &__title {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    color: map-get($fresh-theme-colors, 'black');

  }

  &__titleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    gap: 8px;

    span {
      width: 100%;
    }

    .PlansCard__label {
      width: auto;
    }
  }

  &__desc {
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
    color: map-get($fresh-theme-colors, 'black');
    margin-bottom: 11px;
  }

  &__label {
    padding: 4px 12px;
    border-radius: 12px;
    background-color: map-get($fresh-theme-colors, 'pink');
    color: #FFFFFF;
    font-size: 12px;
  }


  &__priceValue {
    font-size: 22px;
    font-weight: 600;
    line-height: 120%;
    color: map-get($fresh-theme-colors, 'black');
    margin-bottom: 3px;

    &_crossed {
      text-decoration: line-through;
      opacity: 0.5;
    }
  }

  &__price {
    margin-bottom: 16px;
  }

  &__button {
    width: 100%;
    margin-bottom: 8px;
  }

  &__buttonDesc {
    font-size: 10px;
    font-weight: 400;
    line-height: 120%;
    color: map-get($fresh-theme-colors, 'black');
    text-align: center;
    margin-bottom: 0;
  }

  &__buttonContainer {
    margin-bottom: 16px;
  }

  &__featuresTitle {
    font-size: 14px;
    color: map-get($fresh-theme-colors, 'black');
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 8px;
  }

  &__featuresList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }

  &__feature {
    display: flex;
    align-items: center;
  }

  &__featureTitle {
    margin: 0;
    font-size: 12px;
    color: map-get($fresh-theme-colors, 'black');
    font-weight: 400;
    line-height: 120%;

    &_bold {
      font-weight: 600;
    }
  }

  &__link {
    margin-top: 24px;
    font-size: 12px;
    color: map-get($fresh-theme-colors, 'black');
    font-weight: 400;
    line-height: 120%;
    text-decoration-line: underline;
    text-align: center;
    cursor: pointer;
    margin-top: auto;

    &:hover {
      color: map-get($fresh-theme-colors, 'black');
      text-decoration-line: none;
    }
  }

  &__priceDesc {
    font-size: 10px;
    color: map-get($fresh-theme-colors, 'black');
    font-weight: 700;
    line-height: 120%;
  }
}