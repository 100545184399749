@import './src/variables.scss';

.FreshLinkOutput {
  position: relative;
  display: block;
  width: 100%;


  &__icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 20px;
      height: 20px;
    }

    &_white {
      fill: #FFFFFF;
    }
  }

  &__output {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;
    padding: 14px;
    border-radius: 12px;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'white');
    background: #FFFFFF;
    border: 1px solid map-get($fresh-theme-colors, 'grey-light');
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      line-height: 16px;
      padding: 10px;
      gap: 12px;
    }

    &_dark {
      background: map-get($theme-colors, 'black');
      border: 1px solid map-get($theme-colors, 'black');
      color: #FFFFFF;
    }
  }

  &__outputValue {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
    font-weight: 600;
    width: 100%;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;

    }
  }

  &__btn {
    flex-grow: 1;
  }

  &__copyText {
    font-size: 14px;
    margin: 0;
    position: absolute;
    right: -10px;
    transform: translateX(100%) translateY(-50%);
    top: 50%;
    color: map-get($fresh-theme-colors, 'black');
  }
}