.ReadOnlyWidgetControl {
  align-items: center;
  background: #05051D;
  border-radius: 12px;
  color: #fff;
  display: flex;
  height: 60px;
  justify-content: center;
  width: 100%;
  padding: 5px 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;

  &--isVertical{
    flex-direction: column;
  }

  &--justifyStart {
    justify-content: start;
  }

  &__icon-wrapper {  
    margin-right: 10px; 
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }

    &--isVertical{
      margin: 0;
      margin-bottom: 5px; 
    }   
  }
}
