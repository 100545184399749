.MaterialIcons {
  // display: flex;
  // flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  justify-content: center;
  width: 234px;
  background: #18182E;
  border: 1px solid #05051D;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;

  position: absolute;
  top: -40px;
  left: -260px;
}