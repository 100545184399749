@import '/src/variables.scss';

.AnalyticsPercentageTable {
  border-radius: 20px;
  border: 1px solid map-get($fresh-theme-colors, 'grey-light');
  padding: 30px;
  min-height: 290px;


  @media (max-width: map-get($grid-breakpoints, lg)) {
    min-height: auto;
  }

  &__title {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 16px;


    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 12px;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 12px;
    background-color: map-get($fresh-theme-colors, 'grey-background');

    svg {
      width: 24px;
      height: 24px;
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 36px;
      height: 36px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      gap: 10px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__titleContainer {
    display: flex;
    align-items: center;
    gap: 14px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      gap: 10px;
    }
  }

  &__itemTitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-right: 10px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      line-height: 14px;
    }

  }

  &__value {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      line-height: 14px;
    }
  }
}