@import '/src/variables.scss';

.FreshModal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  display: none;
  overflow: auto;
  z-index: 10000;

  &_isOpen {
    display: block;
  }

  &__wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
    background: rgba(164, 155, 164, 0.8);
    color: white;
    overflow: auto;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      padding: 30px 0;
    }

    &_theme {
      &_dark {
        background: rgba(5, 5, 29, 0.8);
      }
    }

    &_isOpen {
      display: flex;
    }


    &_position {
      &_right {
        align-items: flex-start;
        justify-content: flex-end;
        padding: 16px;
      }
    }
  }

  &__dialog {
    display: flex;
    flex-direction: column;
    padding: 40px;
    background: #ffffff;
    position: relative;
    box-sizing: border-box;
    border-radius: 16px;
    width: 440px;

    &_size {
      &_sm {
        width: 440px;
      }

      &_md {
        width: 560px;
      }

      &_lg {
        width: 974px;
      }
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 90%;
      padding: 30px;
    }

    &_template {
      &_light {
        background: #ffffff;
        color: map-get($fresh-theme-colors, 'black');
      }

      &_dark {
        background: #18182E;
        color: #ffffff;

        .FreshModal__title {
          color: #FFFFFF;
        }

        .FreshModal__closeBtn {
          background: #05051D;
        }
      }
    }
  }

  &__title {
    display: block;
    margin-bottom: 40px;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    padding: 0 15px;
    text-align: center;
    color: map-get($fresh-theme-colors, 'black');

    &_centered {
      text-align: center;
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 24px;
    }
  }

  &__closeBtnIcon {
    stroke: #ffffff;
    transition: 0.2s ease all;
  }

  &__closeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    right: 16px;
    border: none;
    background: #05051D;
    text-decoration: none;
    cursor: pointer;
    padding: 0;
    border-radius: 50%;
    transition: 0.2s ease all;


    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 20px;
      height: 20px;
    }

    &:hover {
      background: map-get($fresh-theme-colors, 'pink-dark');
    }
  }
}