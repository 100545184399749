@import 'src/variables.scss';

.StoryAnalyticsGeneral {
  border-bottom: 1px solid map-get($fresh-theme-colors, 'grey-light');
  display: flex;
  gap: 20px;
  width: 335px;
  padding-bottom: 24px;

  &__previewContainer {
    position: relative;
    width: 68px;
    height: 123px;
    border-radius: 12px;
    overflow: hidden;
    flex-shrink: 0;
    border: 1px solid map-get($fresh-theme-colors, 'grey-light');

    &_isLoading {
      font-size: 0;
    }

    &:hover {
      .StoryAnalyticsGeneral__previewMoreBtn {
        opacity: 1;
      }
    }
  }

  &__previewMoreBtn {
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: map-get($fresh-theme-colors, 'pink'), $alpha: 0.9);
    transition: 0.3s ease opacity;
  }

  &__previewMoreBtnText {
    margin: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
  }

  &__analytics {
    width: 100%;
    padding-top: 10px;
  }

  &__analyticsItem {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__analyticsItemTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'grey');
    text-transform: capitalize;
  }

  &__analyticsItemCount {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: map-get($theme-colors, 'black');
    margin-left: 20px;
  }

}