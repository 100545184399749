@import 'src/variables.scss';

.SettingsButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 10px;
  height: 34px;
  background: #05051D;
  border-radius: 8px;
  border: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: $light-grey;

  &__icon {
    margin-right: 8px;

    & svg>path {
      stroke: #AFAFAF !important;

    }
  }

  &:hover {
    background: map-get($fresh-theme-colors, 'pink');
    color: #FFFFFF;

    & svg>path {
      stroke: #FFFFFF !important;
    }
  }
}