@import '/src/variables.scss';

.StoryAnalyticsCard {
  padding: 24px;
  max-width: 385px;
  min-height: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 400px;
    background: map-get($fresh-theme-colors, 'grey-light');
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  &__statListItem {
    display: flex;
    justify-content: space-between;
  }

  &__statListItem {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__statListItemTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');
    text-transform: capitalize;

    &_thin {
      font-weight: 400;
    }

    &_grey {
      color: #A49BA4;
    }

    &_emoji {
      font-size: 24px;
      line-height: 24px;
    }
  }

  &__statListItemValue {
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');


    &_grey {
      color: #A49BA4;
    }
  }


  &__statListItemValueAdditional {
    color: #A49BA4;
  }

  &__noDataTitle {
    margin: 0;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    margin-bottom: 8px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: map-get($fresh-theme-colors, 'grey');
  }

  &__noDataText {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: map-get($theme-colors, 'black');
    margin: 0;
  }

  &__widgetsList {
    padding-top: 24px;
    flex-grow: 1;
    overflow-y: auto;
    max-height: 400px;
    @include use-light-scroll;
  }
}