@import 'src/variables.scss';

.Accordion {
  border: 1px solid #05051D;
  border-radius: 8px;

  &__header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    &_noClickable {
      cursor: default;
    }
  }

  &__titleColumn {
    display: flex;
  }

  &__title {
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
  }

  &__icon {
    width: 18px;
    margin-right: 5px;

    &_apple {
      fill: #FFFFFF;
    }

    &_android {
      fill: #32DE84;
    }
  }

  &__body {
    display: none;
    padding: 0 16px 16px 16px;

    &_showed {
      display: block;
    }
  }

  &__switch {
    .custom-control-label {
      cursor: pointer !important;
      &::before {
        background-color: map-get($theme-colors, 'black') !important;
        border-radius: 17px !important;
        border: none !important;
        width: 36px !important;
        height: 24px !important;
        top: 0 !important;
      }
      
      &::after {
        width: 20px !important;
        height: 20px !important;
        background-color: map-get($theme-colors, 'white') !important;
        border-radius: 50% !important;
        top: 2px !important;
      }
    }

    .custom-control-input ~ .custom-control-label::before {
      box-shadow: none !important;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      color: #fff !important;
      border-color: transparent !important;
      background-color: $pink !important;
    }
 
  }
}