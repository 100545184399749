@import './src/variables.scss';

.AnalyticsHeader {
  &__header {
    display: flex;
    align-items: center;
  }

  &__headerContainer {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 20px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  &__navItem {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'grey');
    transition: 0.2s ease all;
    padding: 0 24px;

    &_active {
      color: map-get($fresh-theme-colors, 'black');
    }

    &:first-child {
      border-right: 1px solid map-get($fresh-theme-colors, 'grey-light');
    }

    &:hover {
      color: map-get($fresh-theme-colors, 'black');
      text-decoration: none;
    }
  }

  &__headerTitle {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    color: map-get($fresh-theme-colors, 'black');
    margin: 0;
    word-break: keep-all;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  &__headerRightContainer {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: auto;
    flex-shrink: 0;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 100%;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-left: 0;
    }
  }

  &__headerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 68px;
  }

  &__headerContentTitle {
    color: #fff;
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
  }

  &__shield {
    background: map-get($fresh-theme-colors, 'pink');
    border-radius: 12px;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
    padding: 2px 5px;
    margin-left: 5px;
  }
}