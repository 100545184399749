.Button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 8px;
  border-radius: 8px;

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }

  &__text {
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    &_marginLeft {
      margin-left: 7px;
    }
  }

  &_block {
    width: 100%;
  }

  &--black {
    background: #05051D !important;
  }

  &--grey-dark {
    background: #18182E !important;
  }

  &--pink {
    background: #FF00D0 !important;
  }
}