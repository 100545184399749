.GiphyWidgetList {
  &__search {
    margin-bottom: 12px;
    background: none !important;

    & > input {
      background: #05051D;
      border-radius: 8px;
      font-size: 16px;
      line-height: 19px;
      padding: 8px 8px 8px 34px;
      color: #FFFFFF;
    }

    & > div {
      display: none;
    }
  }

  &__grid {
    display: flex;
    justify-content: center;
  }
}