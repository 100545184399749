@import '/src/variables.scss';

.SettingsTextarea {
  width: 100%;
  min-height: 80px;
  background: #05051D;
  border-radius: 8px;
  color: #ffffff;
  padding: 12px;
  font-size: 12px;
  line-height: 15px;
  border: 1px solid #05051D;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &_marginBottom {
    margin-bottom: 16px;
  }

  @include use-dark-scroll;

  &::placeholder {
    color: #AFAFAF;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 6px 0px $pink;
    border: 1px solid $pink;
  }

  &::-webkit-resizer {
    background: #05051D;
  }
  
}
