@import 'src/variables.scss';

.SelectDropdown {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 184px;
  height: 152px;
  border: 1px solid #E9E6E9;
  background: #ffffff;
  box-sizing: border-box;
  overflow-y: auto;
  border-radius: 12px;
  margin-top: 15px;
  margin-left: -12px;

  @include use-light-scroll;
}