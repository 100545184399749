@import '/src/variables.scss';

.ColorThemes {
  width: 234px;
  background: map-get($theme-colors, 'grey');
  border: 1px solid map-get($theme-colors, 'black');
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
  position: absolute;
  left: -260px;
  top: -70px;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}