@import 'src/variables.scss';

.ChooseAppContainer {
  display: flex;
  align-items: center;

  &__select {
    background: #FFFFFF !important;
    border: none;
    color: map-get($fresh-theme-colors, 'black');
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  &__control {
    position: relative;
    display: flex;
    align-items: center;
    border: none;
    color: map-get($fresh-theme-colors, 'black');
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    padding: 0;
    transition: 0.2s color ease;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 10px;
      line-height: 14px;
    }

    &:hover {
      color: map-get($fresh-theme-colors, 'pink');
    }
  }

  &__addIcon {
    fill: white;
    width: 10px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 8px;
    }
  }

  &__addIconWrapper {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    border-radius: 50px;
    background-color: $pink;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 14px;
      height: 14px;
    }
  }

  &__chevronIcon {
    right: 12px;
  }

  &__item {
    padding: 0 24px;
    border-right: 1px solid #E9E6E9;
    margin-right: 24px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      padding: 0 12px;
      margin-right: 12px;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-left: 0;
      padding-right: 0;
      margin-right: 0;
      border-right: none;
    }
  }

  &__selectBtn {
    align-items: center;
    border: none;
    display: flex;
    justify-content: space-between;
    padding: 0;
    cursor: pointer;
    gap: 12px;

    &:hover {
      .ChooseAppContainer__selectBtnText {
        color: map-get($fresh-theme-colors, 'pink');
      }

      .ChooseAppContainer__selectBtnArrow {
        stroke: map-get($fresh-theme-colors, 'pink');
      }
    }
  }

  &__selectBtnArrow {
    transition: 0.225s ease all;
  }

  &__selectBtnText {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');
    transition: 0.225s ease all;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
    }
  }

  &__selectModal {
    transform: translate(-15px, 25px);

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 300px !important;
      position: absolute;
      top: 10px;
      left: 70%;
      transform: translateX(-50%);
    }
  }

  &__addText {
    @media (max-width: map-get($grid-breakpoints, lg)) {
      display: none;
    }
  }
}