@import './src/variables.scss';

.GroupStoriesTab {
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    gap: 20px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      gap: 14px;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__headerContainer {
    display: flex;
    align-items: center;
    flex-grow: 1;
    overflow-x: hidden;
    width: 100%;
  }

  &__headerBtnContainer {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-shrink: 0;
  }

  &__headerCount {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'grey');
    padding: 8px 12px;
    background: #F8F7F8;
    border-radius: 12px;
  }

  &__headerTitle {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    color: map-get($fresh-theme-colors, 'black');
    margin: 0;
    word-break: keep-all;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 14px 16px;
    color: map-get($fresh-theme-colors, 'black');
    background: map-get($fresh-theme-colors, 'grey-background');
    border-radius: 12px;
    text-decoration: none;
    transition: .225s all ease-in-out;
    flex-shrink: 0;

    &:hover {
      background: map-get($fresh-theme-colors, 'pink');
      color: #FFFFFF;
      text-decoration: none;

      .GroupStoriesTab__btnIcon {
        stroke: #FFFFFF;
      }
    }
  }

  &__filtersStories {
    margin-right: 24px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-right: 0;
    }
  }

  &__btnMain {
    border: none;
  }

  &__btnMainIcon {
    width: 20px;
    height: 20px;
    stroke: #FFFFFF;
  }

  &__btnIcon {
    width: 20px;
    margin-right: 12px;
    transition: .225s all ease-in-out;
  }

  &__filters {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      flex-direction: column;
      align-items: flex-start;
      gap: 14px;
    }
  }

  &__emptyStory {
    position: relative;
    width: 234px;
    height: 416px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    overflow: hidden;
    border: 1px dashed #D2CDD1;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 180px;
      height: 320px;
    }
  }

  &__emptyStoryTitle {
    text-align: center;
    color: map-get($fresh-theme-colors, 'grey');

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      line-height: 16px;
    }
  }
}