@import "/src/variables.scss";

.AppIntegrationsTab {
  &__title {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 12px;
    color: map-get($fresh-theme-colors, 'black');

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 8px;
    }
  }

  &__header {
    margin-bottom: 28px;
  }

  &__icon {
    width: 44px;
    height: 44px;
    margin-bottom: 10px;
  }
}