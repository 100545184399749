@import './src/variables.scss';

.StoryVideoBackground {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &__video {
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: 0.5s ease all;

    &_cover {
      object-fit: cover;
    }

    &_loading {
      opacity: 0;
    }
  }

  &__loadText {
    width: 100%;
    opacity: 0;
    color: map-get($fresh-theme-colors, 'grey');
    font-size: 16px;
    margin: 0;
    padding: 0 10px;
    position: absolute;
    left: 0;
    top: 50%;
    text-align: center;
    transform: translateY(-50%);
    transition: 0.5s ease all;

    &_show {
      opacity: 1;
    }
  }

}