@import './src/variables.scss';

.SubscriptionStatusModal {
  &__selector {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #E9E6E9;
    padding: 14px;
  }

  &__dropdown {
    width: 100%;

    .btn {
      width: 100%;
    }
  }

  &__title {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    margin: 0;
    color: map-get($theme-colors, 'black');
  }

  &__btn {
    margin-top: 40px;
  }

  &__menu {
    background-color: #FFFFFF;
    width: 100%;
    margin-top: 12px;
    border: 1px solid #E9E6E9;
    border-radius: 12px;
    overflow: hidden;
  }

  &__menuContentItemTitle {
    margin: 0;
  }

  &__menuContentItem {
    display: flex;
    align-items: center;
    height: 40px;
    cursor: pointer;
    padding: 14px;

    &:hover {
      background-color: #F7F7F7;
    }
  }

  &__menuContentItemTitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
    color: map-get($theme-colors, 'black');
  }

  &__cancelInfoText {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: map-get($theme-colors, 'black');
    margin: 0;
  }

  &__cancelInfo {
    margin-top: 16px;
  }
}