/**
 * http://applemusic.tumblr.com/
 * https://jsfiddle.net/xq56dmrh/
 */

// /** Ultra Light */
// @font-face {
//   font-family: "San Francisco";
//   font-weight: 100;
//   src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-ultralight-webfont.woff");
// }

// /** Thin */
// @font-face {
//   font-family: "San Francisco";
//   font-weight: 200;
//   src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-thin-webfont.woff");
// }

// /** Regular */
// @font-face {
//   font-family: "San Francisco";
//   font-weight: 400;
//   src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
// }

// /** Medium */
// @font-face {
//   font-family: "San Francisco";
//   font-weight: 500;
//   src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-medium-webfont.woff");
// }

// /** Semi Bold */
// @font-face {
//   font-family: "San Francisco";
//   font-weight: 600;
//   src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-semibold-webfont.woff");
// }

// /** Bold */
// @font-face {
//   font-family: "San Francisco";
//   font-weight: 700;
//   src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-bold-webfont.woff");
// }

@font-face {
  font-family: "San Francisco";
  font-weight: 300;
  src: url("./fonts/SFProText-Light.ttf") format('truetype');
  font-style: normal;
}

@font-face {
  font-family: "San Francisco";
  font-weight: 400;
  src: url("./fonts/SFProText-Regular.ttf") format('truetype');
  font-style: normal;
}

@font-face {
  font-family: "San Francisco";
  font-weight: 700;
  src: url("./fonts/SFProText-Bold.ttf") format('truetype');
  font-style: normal;
}

@font-face {
  font-family: "San Francisco";
  font-weight: 300;
  src: url("./fonts/SFProText-LightItalic.ttf") format('truetype');
  font-style: italic;
}

@font-face {
  font-family: "San Francisco";
  font-weight: 400;
  src: url("./fonts/SFProText-RegularItalic.ttf") format('truetype');
  font-style: italic;
}

@font-face {
  font-family: "San Francisco";
  font-weight: 700;
  src: url("./fonts/SFProText-BoldItalic.ttf") format('truetype');
  font-style: italic;
}

@font-face {
  font-family: "SF Compact Rounded";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/SF-Compact-Rounded-Medium.otf") format('opentype');
}

@font-face {
  font-family: "SF Compact Rounded";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/SF-Compact-Rounded-Bold.otf") format('opentype');
}

@font-face {
  font-family: "SF Compact Rounded";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/SF-Compact-Rounded-Regular.otf") format('opentype');
}

@font-face {
  font-family: "SF Compact Rounded";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/SF-Compact-Rounded-Light.otf") format('opentype');
}