.WidgetThemes {
    width: 234px;
    background: #18182E;
    border: 1px solid #05051D;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px;
    position: absolute;
    left: -260px;
    top: -70px;
    z-index: 1;

    &__title {
        color: #ffffff;
    }

    &__colors {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
}
