@import './src/variables.scss';

.AuthPageMessage {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__icon {
        width: 64px;
        height: 64px;
        margin-bottom: 24px;
    }

    &__btn {
        margin-bottom: 12px;
    }

    &__title {
        font-size: 14px;
        line-height: 20px;
        color:  map-get($fresh-theme-colors, 'black');
        text-align: center;
        padding: 0 40px;
        margin-bottom: 40px;

        &_bold {
            font-weight: 600;
        }
    }
    
    &__timer-text {
        font-size: 12px;
        line-height: 18px;
        color: map-get($fresh-theme-colors, 'grey');
        margin: 0;
    }
}