.StoryDesignTools {
  display: flex;
  justify-content: space-between;

  &__colorPicker {
    margin-bottom: 16px;
  }

  &__col {
    display: flex;
  }

  &__fullWidth {
    width: 100%;
    height: 40px;
  }

  &__btn {
    & svg>path {
      stroke: #FFFFFF !important;
    }

    &_margin-right {
      margin-right: 10px;
    }

    &_positionBtn {
      margin-right: 10px;
    }

    &_positionBtn svg>path {
      fill: #FFFFFF !important;
    }

    &:not(&:last-child) {
      margin-bottom: 12px;
    }
  }

  &__btn-icon {
    margin-right: 6px;

    &_single {
      margin: 0;
    }
  }

  &__btn-text {
    margin-left: 6px;
  }

  &__btn-chevron {
    fill: #FFFFFF;
    margin-left: 6px;
  }

  &__btn-color-preview {
    width: 14px;
    height: 14px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 2px;
    margin-right: 6px;
  }

  &__publishText {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin: 0;

    &_grey {
      color: #A49BA4;
    }
  }

  &__publishTextContainer {
    margin-bottom: 16px;
  }
}