@import "./src/variables.scss";

.InformMessage {
  background-color: #fff;
  border-radius: 8px;
  width: 320px;
  padding: 19px;
  margin-bottom: 4px;
  position: relative;

  border: 1px solid #E9E6E9;
  box-shadow: 0px 12px 24px rgba(42, 30, 42, 0.1);
  border-radius: 12px;

  animation-name: toTop;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;

  &_type {
    &_share {
      width: 360px;
    }
  }

  &__closeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 19px;
    right: 19px;
    border: none;
    background: #F8F7F8;
    text-decoration: none;
    cursor: pointer;
    padding: 0;
    border-radius: 50%;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    color: map-get($fresh-theme-colors, 'black');
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }

  &__shareContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__qrContainer {
    display: flex;
    justify-content: center;
  }

  &__qrWrapper {
    padding: 10px;
    background: #FFFFFF;
  }

  &__desc {
    font-size: 14px;
    line-height: 20px;
    color: map-get($theme-colors, 'grey');
    margin: 0;
    text-align: center;
  }

  &__text {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'grey');
    margin: 0;

    &_marginBottom {
      margin-bottom: 8px;
    }

    &_small {
      font-size: 12px;
    }
  }

  &__titleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &_marginBottom {
      margin-bottom: 40px;
    }
  }

  &__icon {
    margin-right: 15px;
  }

  &__btnIcon {
    stroke: #FFFFFF;
  }

  &__progressBar {
    width: 100%;
    height: 8px;
    border-radius: 12px;
    background: map-get($fresh-theme-colors, 'grey-background');
    display: flex;
    align-items: center;
    padding: 0 2px;
  }

  &__progressBarLine {
    width: 0;
    height: 8px;
    display: block;
    border-radius: 12px;
    background-color: map-get($fresh-theme-colors, 'pink');
    transition: 0.225s ease all;
  }
}

@keyframes toTop {
  from {
    margin-top: 500px;
    opacity: 0;
  }

  70% {
    margin-top: -20px;
  }

  to {
    opacity: 1;
    margin-top: 0;
  }
}