.WidgetOutline {
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  left: 0;
  pointer-events: none;

  &__outline {
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    border-style: solid;
    border-color: #EB5757;
    z-index: 9999;
  }
}