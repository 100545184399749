@import './src/variables.scss';

.TeamTab {
  &__actions {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__actionButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 0;

    &:disabled {
      cursor: auto;
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &__actionIcon {
    fill: map-get($fresh-theme-colors, 'pink');


    &_stroke {
      fill: none;
      stroke: map-get($fresh-theme-colors, 'pink');
    }
  }
}