.SettingsTabLayout {
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__title {
    padding-bottom: 0 !important;
  }

  &__body {
    width: 440px;

    &_wide {
      width: 100%;
    }
  }
}