@import "./src/variables.scss";

.GroupsLine {
  display: none;
  padding: 5px;
  background: map-get($fresh-theme-colors, 'grey-background');
  color: map-get($fresh-theme-colors, 'black');

  &_showed {
    display: block;
    width: 100%;
    overflow-x: auto;

    @include use-light-scroll;

    &::-webkit-scrollbar-thumb {
      border: 5px solid map-get($fresh-theme-colors, 'grey-background');
    }
  }

  &__groupsList {
    display: flex;
    padding: 0;
  }

  &__addGroupIcon {
    transition: .225s all ease-in-out;
  }

  &__groupItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: none;
    padding: 0 10px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: map-get($fresh-theme-colors, 'black');
    text-decoration: none;
    transition: .225s all ease-in-out;

    &_active {
      color: map-get($fresh-theme-colors, 'pink');
    }

    &_grey {
      color: map-get($fresh-theme-colors, 'grey');
    }

    &:hover {
      color: map-get($fresh-theme-colors, 'black');
      text-decoration: none;

      .GroupsLine__addGroup {
        background: map-get($fresh-theme-colors, 'pink');
      }

      .GroupsLine__addGroupIcon {
        stroke: #FFFFFF;
      }
    }

    &_noHover {
      &:hover {
        .GroupsLine__addGroup {
          background: transparent;
        }
      }
    }

    &:focus {
      outline: none;
    }
  }

  &__groupTitle {
    margin-top: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 10px;
      margin-top: 0;
    }
  }

  &__addGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border: none;
    border-radius: 50%;
    background: #FFFFFF;
    transition: .225s all ease-in-out;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 48px;
      height: 48px;
    }

    &_squared {
      border-radius: 12px;
    }

    &_noBackground {
      background: transparent;
    }
  }

  &__addGroupIcon {
    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 10px;
    }
  }
}