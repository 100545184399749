@import './src/variables.scss';

.GroupAvatar {
  padding: 2px;
  border-radius: 50%;
  border: 2px solid #E9E6E9;
  background: transparent;
  transition: .2s all ease-in;


  &:hover {
    border: 2px solid map-get($fresh-theme-colors, 'pink');
  }

  &__container {
    position: relative;
  }

  &__editIcon {
    position: absolute;
    width: 26px;
    height: 26px;
    right: -4px;
    top: -4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    border-color: map-get($fresh-theme-colors, 'grey-light');
    background-color: #FFFFFF;
    transition: .2s all ease-in;
    opacity: 0;

    &_visible {
      opacity: 1;
    }
  }

  &_size {
    &_sm {
      width: 28px;
      height: 28px;
    }

    &_lg {
      width: 64px;
      height: 64px;
    }
  }

  &_current {
    border: 2px solid map-get($fresh-theme-colors, 'pink');
  }

  &_squared {
    border-radius: 12px;
  }
}