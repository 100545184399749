.Calendar {
  border-radius: 4px;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.04);
  font-size: 16px;
  list-style-type: none;
  margin: 0;
  padding: 15px 15px 19px 15px;
  background-color: #18182e;
  border: 1px solid #4e4e53;
  color: #d1cfd7;
  width: 300px;
  box-sizing: border-box;
  height: 320px;

  &--withTime {
    display: flex;
    justify-content: space-between;
    width: 380px;
  }

  &__month {
    box-sizing: border-box;
    &--withTime {
      width: 270px;
    }
  }

  &__week {
    display: table;
    &-name {
      display: table-cell;
      height: 38px;
      text-align: center;
      vertical-align: middle;
      width: 38px;

      &-end {
        color: rgba(#d1cfd7, 0.4);
      }
    }
  }

  &__day-empty {
    background: transparent;
    border: none;
    color: #d1cfd7;
    font-size: 16px;
    width: 38px;
  }

  &__time {
    width: calc(100% - 280px);
  }
}
