@import "/src/variables.scss";

.AnalyticsStoryGroupsTab {
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    gap: 20px;
  }

  &__tableText {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    z-index: 2;
    color: map-get($fresh-theme-colors, 'black');
    margin: 0;

    &_bold {
      font-weight: 600;
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      line-height: 14px;
      white-space: nowrap;
    }
  }

  &__indicators {
    display: flex;
    gap: 17px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__title {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 16px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 12px;
    }
  }

  &__chart {
    width: 100%;
    border-radius: 20px;
    border: 1px solid map-get($fresh-theme-colors, 'grey-light');
    padding: 40px;
  }

  &__chartContainer {
    width: 100%;
  }

  &__tableCol {
    display: flex;
    justify-content: center;
    align-items: center;

    &_first {
      justify-content: flex-start;
      position: relative;
    }

    &_link {
      cursor: pointer;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__targetStats {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      grid-template-columns: auto;
      grid-template-areas: 'indicators indicators'
        'targetStatsItem targetStatsItem';
      ;
    }
  }

  &__tableGroupImg {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin-right: 16px;
    position: relative;
    z-index: 2;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
  }

  &__targetStatsItem {
    border-radius: 20px;
    border: 1px solid map-get($fresh-theme-colors, 'grey-light');
    padding: 20px;
    flex-grow: 1;
    max-width: 600px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      grid-area: targetStatsItem;
      width: 100%;
      max-width: none;
    }
  }

  &__targetStatsIndicator {
    width: 290px;
    height: 100%;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 100%;
    }
  }

  &__targetStatsIndicators {
    display: flex;
    gap: 16px;
    height: 100%;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      flex-direction: column;
      width: 100%;
    }
  }
}