@import 'src/variables.scss';

.SettingsStoriesPreview {
  &__stories {
    border-radius: 8px;
    padding-bottom: 4px;
    background-color: map-get($theme-colors, 'black');
  }

  &__storiesContainer {
    display: flex;
    overflow-x: auto;
    gap: 8px;
    padding: 8px;
    padding-bottom: 4px;

    @include use-dark-scroll;
  }

  &__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: map-get($fresh-theme-colors, 'grey-background');
    transition: .3s all ease-in-out;
  }

  &__plus-icon {
    width: 12px;
    height: 12px;
    transition: .3s all ease-in-out;
  }

  &__card {
    width: 44px;
    height: 78px;
    border-radius: 4px;
    overflow: hidden;
    flex-shrink: 0;
    transition: 0.3s all ease-in-out;
    border: 1px solid transparent;

    &:hover,
    &_current {
      border: 1px solid #FD19CC;
      cursor: pointer;

      .SettingsStoriesPreview__circle {
        background-color: #FD19CC;
      }

      .SettingsStoriesPreview__plus-icon {
        fill: #FFFFFF;
      }
    }

    &_add {
      background-color: map-get($theme-colors, 'white');
      border: 1px dashed #E9E6E9;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        border: 1px dashed #FD19CC;
      }
    }
  }
}