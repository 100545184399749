@import './src/variables.scss';

.GradientPicker {
  &__selector {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 12px;
    border-radius: 50px;
    padding: 1px;
    margin-bottom: 12px;
  }

  &__color-btn {
    width: 10px;
    height: 10px;
    border: 2px solid #FFFFFF;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
    border-radius: 50%;
    background: none;
    color: transparent;
    padding: 0;

    &_current {
      width: 16px;
      height: 16px;
      border: 4px solid map-get($fresh-theme-colors, 'pink');
      transform: translateY(-50%);
      position: relative;
      top: 50%;
    }

    &_first {
      left: -4px;
    }

    &_second {
      right: -4px;
    }
  }
}