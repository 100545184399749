.EInput {
  background-color: #05051d;
  border-radius: 5px;
  padding: 10px;
  color: #fff;
  border: 1px solid transparent;
  box-sizing: border-box;
  
  &:hover,
  &:active,
  &:focus {
    border: 1px solid #ff80e8;
    box-shadow: 0 0 0 4px rgba(255, 0, 208, .25);;
  }
}
