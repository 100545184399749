.ColorPicker {
  width: 100%;
  position: relative;
  z-index: 100;

  &__button {
    width: 100%;
    border: none;
    border-radius: 8px;
    background: #05051D;
    color: #fff;
    padding: 0 12px;
    height: 34px;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);

    &_light {
      background: #fff;
      color: #05051D;
    }
  }

  &__color {
    display: block;
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border-radius: 50%;
    margin-right: 8px;
    border: 1px solid #E9E6E9;
    flex-shrink: 0;
  }

  &__colorInput {
    background: transparent;
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    border: none;
    width: 80px;
    text-transform: uppercase;
  }

  &__dropdown {
    position: absolute;
    top: 0;
    left: -264px;
    display: none;
    width: 238px;
    background: #18182E;
    border: 1px solid #05051D;
    border-radius: 8px;
    padding: 12px;
    z-index: 1;

    &_open {
      display: block;
    }
  }

  &__tab {
    display: none;

    &_current {
      display: block;
    }
  }

  &__header {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
  }

  &__tabControl {
    height: 32px;
    width: 32px;
    padding: 0;
    border-radius: 50px;
    margin: 0 6px;
    border: none;
    color: transparent;

    &_type {
      &_color {
        background: #474764;

        &.ColorPicker__tabControl_current {
          background: #FF00D0;
        }
      }

      &_gradient {
        background: linear-gradient(180deg, #FFFFFF 0%, #474764 100%);
        opacity: 0.5;

        &.ColorPicker__tabControl_current {
          background: linear-gradient(180deg, #FFFFFF 0%, #FF00D0 100%);
          opacity: 1;
        }
      }

      &_image {
        display: flex;
        align-items: center;
        justify-content: center;
        color: inherit;
        background: transparent;
      }
    }
  }
}