@import 'src/variables.scss';

.GroupViewItem {
  position: relative;
  width: 68px;
  height: 68px;
  margin-right: 20px;
  margin-bottom: 18px;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    width: 52px;
    height: 52px;
    margin-right: 10px;
  }

  &:last-child {
    margin-right: 0;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: 50%;

    border: 2px solid #E9E6E9;
  }

  &_type {
    &_square {
      &::after {
        border-radius: 10px;
      }
    }

    &_bigSquare {
      width: 86px;
      height: 86px;
      margin-bottom: 0;

      &::after {
        border-radius: 10px;
      }
    }

    &_rectangle {
      width: 68px;
      height: 86px;
      margin-bottom: 0;

      &::after {
        border-radius: 10px;
      }
    }
  }

  &_active {
    &::after {
      border: 2px solid transparent;
      background: linear-gradient(-45deg, #B90AE0, #FF0198) border-box;
      -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }

  &__imgContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
    width: 60px;
    height: 60px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 48px;
      height: 48px;
    }

    &_type {
      &_square {
        border-radius: 7px;
      }

      &_bigSquare {
        width: 78px;
        height: 78px;
        border-radius: 7px;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 7px;
        }
      }

      &_rectangle {
        width: 60px;
        height: 78px;
        border-radius: 7px;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 7px;
        }
      }
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;

    &_type {
      &_square {
        border-radius: 7px;
      }

      &_bigSquare {
        border-radius: 7px;
      }

      &_rectangle {
        border-radius: 7px;
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -20px;
    margin: 0;
    color: #2B1E2A;
    word-break: keep-all;
    white-space: nowrap;

    &_active {
      color: #FD19CC;
    }


    &_type {
      &_bigSquare {
        color: #FFFFFF;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        bottom: 10px;
        left: 10px;
        transform: none;
      }

      &_rectangle {
        color: #FFFFFF;
        font-weight: 600;
        font-size: 8px;
        line-height: 12px;
        bottom: 10px;
        left: 10px;
        transform: none;
      }
    }
  }
}