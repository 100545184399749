@import './src/variables.scss';

.DashboardStories {
  display: flex;
  width: 100%;
  overflow-x: auto;
  margin: 0;
  @include use-light-scroll;

  &__story {
    position: relative;
    width: 234px;
    height: 416px;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid #E9E6E9;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 180px;
      height: 320px;
    }

    &_add {
      border: 1px dashed #D2CDD1;
    }
  }

  &__storyItem {
    margin-right: 24px;
  }

  &__storyHead {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
  }

  &__storyStatus {
    padding: 2px 10px;
    border-radius: 8px;
    background: map-get($theme-colors, 'grey');
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 10px;
      line-height: 14px;
      border-radius: 4px;
      padding: 2px 6px;
    }
  }

  &__storyChangesLabel {
    padding: 2px 10px;
    border-radius: 8px;
    background: #FFFFFF;
    color: map-get($theme-colors, 'black');
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 10px;
      line-height: 14px;
      border-radius: 4px;
      padding: 2px 6px;
    }
  }

  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    opacity: 0;
    width: 100%;
    height: 100%;
    background: rgba(164, 155, 164, 0.8);
    align-items: center;
    justify-content: center;
    transition: .25s opacity ease-in;
  }

  &__story:hover &__overlay {
    opacity: 1;
  }

  &__mainButtons {
    display: flex;
    flex-direction: column;
  }

  &__toolbarBtnIcon {
    width: 20px;
    height: 20px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 16px;
      height: 16px;
    }

    &_large {
      width: 24px;
      height: 24px;

      @media (max-width: map-get($grid-breakpoints, lg)) {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 16px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');
    background: #FFFFFF;
    border-radius: 12px;
    transition: .225s all ease-in-out;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      padding: 8px;
      font-size: 10px;
      line-height: 14px;
      border-radius: 10px;
    }

    &:hover {
      text-decoration: none;
      color: #FFFFFF;
      background: map-get($fresh-theme-colors, 'pink');
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__btn-icon {
    width: 20px;
    height: 20px;
    transition: .225s all ease-in-out;
    margin-right: 12px;

    &_single {
      margin: 0;
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }

  &__btn:hover &__btn-icon {
    stroke: #FFFFFF;
  }

  &__toolbarBtn {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    background: transparent;
    border-radius: 12px;
    transition: .225s all ease-in-out;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 30px;
      height: 30px;
      border-radius: 8px;
    }

    &:hover {
      background: map-get($fresh-theme-colors, 'black');
    }
  }

  &__toolbarBtnIcon {
    &_white {
      stroke: #FFFFFF;
    }
  }

  &__toolBar {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: calc(100% - 20px);
    display: flex;
    justify-content: center;
    gap: 3px;
  }

  &__storyParamsItem {
    font-size: 12px;
    line-height: 18px;
    color: map-get($fresh-theme-colors, 'grey');
    padding: 0 16px;
    border-right: 1px solid #E9E6E9;

    &:last-child {
      border-right: none;
    }
  }

  &__storyParams {
    display: flex;
    justify-content: center;
    margin-top: 13px;
  }
}