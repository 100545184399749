@import './src/variables.scss';

.GroupChooseItems {
  display: flex;
  gap: 12px;
  width: 100%;
  overflow-x: auto;

  @include use-light-scroll;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 0;
    width: 90px;


    &_chosen {
      .GroupChooseItems__itemImage_hidden {
        opacity: 0;
      }

      .GroupChooseItems__itemImageWrapper {
        border-color: map-get($fresh-theme-colors, 'pink');
      }

      .GroupChooseItems__itemTitle {
        color: map-get($fresh-theme-colors, 'pink');
      }

      .GroupChooseItems__itemImageOverlay {
        opacity: 1;
      }
    }
  }

  &__itemCheckIconContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: map-get($fresh-theme-colors, 'pink');
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__itemImageWrapper {
    position: relative;
    margin-bottom: 6px;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    border: 2px solid map-get($fresh-theme-colors, 'grey-light');
    padding: 2px;
    transition: 0.225s ease all;

    &_squared {
      border-radius: 12px;
    }
  }

  &__itemImageWrapperInner {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }

  &__itemImageOverlay {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.225s ease all;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));

    &_noBackground {
      background: none;
      border-radius: 12px;
    }
  }

  &__itemImage {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 1;
    transition: 0.225s ease all;
  }

  &__itemTitle {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin: 0;
    color: map-get($theme-colors, 'grey');
    transition: 0.225s ease all;
    width: 100%;
    word-break: keep-all;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;

    &_grey {
      color: map-get($fresh-theme-colors, 'grey');
    }
  }

  &__addGroupIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__addGroupIcon {
    width: 12px;
    height: 12px;
  }
}