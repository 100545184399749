.ButtonsGroup {
  display: flex;
  padding: 2px;
  background: #FFFFFF;
  border-radius: 8px;

  &__btn {
    width: 160px;
    padding: 6px;
    border-radius: 8px;
    border: none;
    background: white;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #05051D;

    &_current {
      color: white;
      background: #FF00D0;
      border: 0.5px solid rgba(0, 0, 0, 0.04);
    }
  }
}