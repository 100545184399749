@import "./src/variables";

.EditorHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  background: #05051D;
  padding: 0 24px;
  background-color: map-get($theme-colors, 'grey');
  border-radius: 0px 0px 16px 16px;

  &__column {
    display: flex;
    gap: 12px;
    justify-content: center;
    flex-basis: 33.333%;
    align-items: center;

    &_left {
      justify-content: flex-start;
    }

    &_right {
      justify-content: flex-end;
    }
  }

  &__shareBtn {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
}