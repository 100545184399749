@import 'src/variables.scss';

.HeaderSubscription {
  display: flex;
  align-items: center;
  gap: 8px;
  color: map-get($theme-colors, 'black');
  cursor: pointer;


  @media (max-width: map-get($grid-breakpoints, lg)) {
    display: none;
  }

  svg {
    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 18px;
      height: 18px;
    }
  }

  &__subscriptionTextContainer {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__subscriptionText {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 10px;
    }
  }

  &__subscriptionLimits {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    color: #A49BA4;
    line-height: 100%;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 8px;
    }
  }

  &__mau {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    font-feature-settings: 'liga' off, 'clig' off;
  }

  &__progress {
    width: 100%;
    border-radius: 4px;
    height: 4px;
    background: map-get($fresh-theme-colors, 'grey-light');
    overflow: hidden;
  }

  &__progressBar {
    height: 100%;
    border-radius: 4px;
    background: map-get($fresh-theme-colors, 'pink');
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
}