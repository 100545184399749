@import './src/variables.scss';

.CreateTemplateModal {
  &__dropArea {
    position: relative;
    width: 96px !important;
    height: 96px !important;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  &__btn {
    margin-top: 40px;
  }


  &__drop {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 12px;
    border: 1px solid map-get($fresh-theme-colors, 'grey-light');
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }

  &__dropItem {
    font-size: 14px;
    line-height: 46px;
    cursor: pointer;
    padding: 0 16px;
    transition: 0.2s ease all;

    &:hover {
      background: map-get($fresh-theme-colors, 'grey-light');
    }
  }

  &__image {
    width: 96px;
    height: 96px;
    border-radius: 12px;
    border: 2px solid #FD19CC;
    padding: 2px;
    object-fit: cover;
  }

  &__imageContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }

  .dropdown-menu {
    width: 100%;
  }
}