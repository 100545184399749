@import 'src/variables.scss';

.FreshActionOutput {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: map-get($fresh-theme-colors, 'black');
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  border: 1px solid #E9E6E9;
  background: #F8F7F8;
  border-radius: 12px;
  padding: 14px 16px;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding: 14px;
  }

  &_transparent {
    background: transparent;
  }

  &__wrapper {
    width: 100%;
    position: relative;
  }

  &__container {
    position: relative;
  }

  &__value {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');
    margin-bottom: 0;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__valueContainer {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__description {
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 10px;
      line-height: 14px;
    }
  }

  &__action {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: map-get($fresh-theme-colors, 'pink');
    margin: 0;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 10px;
      line-height: 16px;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}