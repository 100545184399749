@import './src/variables.scss';

.OrderSummaryTab {
  display: grid;
  grid-template-columns: minmax(50%, 600px) minmax(20%, 1fr);
  gap: 40px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
    gap: 24px;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__title {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 12px;
    color: map-get($fresh-theme-colors, 'black');

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 8px;
    }
  }

  &__desc {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: map-get($fresh-theme-colors, 'black');
    margin: 0;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
    }
  }

  &__header {
    margin-bottom: 28px;
  }

  &__headerRight {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  &__titleRight {
    margin: 0;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    color: map-get($fresh-theme-colors, 'black');
    white-space: nowrap;
  }

  &__applyLink {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    text-decoration: none;
    color: map-get($fresh-theme-colors, 'grey');
    cursor: pointer;

    &:hover {
      text-decoration: none;
      color: map-get($fresh-theme-colors, 'grey');
    }
  }

  &__productFeatures {
    padding-top: 100px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      padding-top: 0;
    }
  }

  &__productFeaturesTitle {
    font-size: 14px;
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 17px;
  }

  &__featureIcon {
    width: 12px;
    height: 10px;
    margin-right: 14px;
    stroke: #27AE60;
  }


  &__featureList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }

  &__feature {
    display: flex;
    align-items: center;
  }

  &__featureTitle {
    margin: 0;
    font-size: 12px;
    color: map-get($fresh-theme-colors, 'black');
    font-weight: 400;
    line-height: 120%;

    &_bold {
      font-weight: 700;
    }
  }


}