@import 'src/variables.scss';

.SettingsOpenSelect {
  width: 100%;
  position: relative;
  background: map-get($theme-colors, 'black');
  border: 1px solid map-get($theme-colors, 'grey');
  padding: 4px;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  &__option {
    width: 100%;
    padding: 6px 12px;
    border: none;
    text-align: left;
    color: #FFFFFF;
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: block;
    text-decoration: none;
    background: none;
    border-radius: 3px;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    &_current,
    &:hover {
      background: #FF00D0;
    }
  }
}