.LinkAndActionSelect {
  &__select {
    margin-bottom: 8px;
  }

  &__selectTitle {
    color: #A49BA4;
  }

  &__iconUrl {
    width: 18px;
    height: 18px;
    fill: #A49BA4;
    margin-right: 8px;
  }

  &__iconStory {
    width: 18px;
    height: 18px;
    stroke: #A49BA4;
    margin-right: 8px;
  }

  &__iconExtLink {
    width: 18px;
    height: 18px;
    fill: #A49BA4;
    margin-right: 8px;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__note {
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
  }

  &__noteLink {
    color: #FFFFFF;
    text-decoration: underline;

    &:hover {
      color: #FFFFFF;
    }
  }
}