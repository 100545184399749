@import "/src/variables.scss";

.FreshTable {
  display: grid;
  overflow-x: hidden;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    overflow-x: auto;
  }

  &__tableHeader {
    display: contents;
  }

  &__tableContent {
    display: contents;
  }

  &__tableText {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    z-index: 2;
    color: map-get($fresh-theme-colors, 'black');
    margin: 0;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      line-height: 14px;
    }

    &_bold {
      font-weight: 600;
    }
  }

  &__tableCol {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #E9E6E9;
    padding: 22px;
    transition: 0.225s ease all;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      padding: 12px;
      font-size: 12px;
      line-height: 14px;
    }

    &_start {
      justify-content: flex-start;
    }

    &_head {
      background-color: #F8F7F8;
      border: none;

      &:first-child {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }

      &:last-child {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }

    &_skelet {
      span {
        flex-grow: 1;
      }
    }

    &_first {
      justify-content: flex-start;
      position: relative;
    }

    &_link {
      cursor: pointer;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__tableRow {
    display: contents;
  }

  &__emptyRow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 324px;
  }

  &__emptyText {
    color: map-get($fresh-theme-colors, 'grey');
  }
}