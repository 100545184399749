@import '/src/variables.scss';

.FreshSwitchControl {
  &__switch {
    min-height: 24px !important;
    height: 24px !important;
    padding-left: 0 !important;
    padding-right: 36px !important;

    .custom-control-label {
      cursor: pointer !important;
      &::before {
        background-color: map-get($fresh-theme-colors, 'grey-light') !important;
        border-radius: 1000px !important;
        border: none !important;
        width: 36px !important;
        height: 24px !important;
        top: 0 !important;
        left: 0 !important;
      }
      
      &::after {
        box-shadow: 0px 4px 8px rgba(43, 30, 42, 0.1);
        width: 20px !important;
        height: 20px !important;
        background-color: #FFFFFF !important;
        border-radius: 50% !important;
        top: 2px !important;
        left: 2px !important;
      }
    }

    .custom-control-input ~ .custom-control-label::before {
      box-shadow: none !important;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      color: #fff !important;
      border-color: transparent !important;
      background-color: map-get($fresh-theme-colors, 'pink')!important;
    }
    

    .custom-control-input {
      width: 36px !important;
      height: 24px !important;
    }

    // .custom-control-input:checked ~ .custom-control-label::after {
    //   transform: translateX(0.5rem) !important;
    // }
  }
}