@import 'src/variables.scss';

.DropArea { 
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background-color: map-get($theme-colors, 'black');
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
  }

  &__icon {
    width: 24px;
    height: auto;
    opacity: 0.5;
    stroke: map-get($theme-colors, 'white');
  }

  &_theme {
    &_light {
      background-color: map-get($theme-colors, 'white');
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);

      .DropArea__icon {
        stroke: #05051D;
      }
    }
  }
}