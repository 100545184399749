@import 'src/variables.scss';

.SearchSelect {
  width: 320px;
  border-radius: 12px;
  border: 1px solid map-get($fresh-theme-colors, 'grey-light');
  background: #FFFFFF;
  box-shadow: 0px 12px 24px 0px rgba(42, 30, 42, 0.10);
  overflow: hidden;

  &__optionsItem {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');
    background: #FFFFFF;
    padding: 12px 16px;
    transition: 0.225s ease all;
    cursor: pointer;

    &_selected {
      color: map-get($fresh-theme-colors, 'pink');
    }

    &:hover {
      background: map-get($fresh-theme-colors, 'grey-background');
    }
  }

  &__icon {
    &>svg {
      width: 18px;
      height: 12px;
    }
  }

  &__options {
    max-height: 264px;

    .simplebar-vertical {
      right: 5px;
    }

    .simplebar-scrollbar:before {
      background: map-get($fresh-theme-colors, 'grey-light');

    }
  }

  &__searchContainer {
    padding: 8px;
  }

  &__searchInput {
    input {
      padding: 8px 16px;
      border-radius: 8px;
    }
  }
}