.ControlGroup {
  display: flex;
  width: 100%;
  align-items: center;

  &__items {
    display: flex;
    width: 100%;
  }

  &__items>.SettingsInput:not(.SettingsInput:last-child),
  &__items>.SettingsSelect:not(.SettingsSelect:last-child)>.SettingsSelect__btn {
    border-radius: 8px 0 0 8px;
    border-right: 1px solid #18182E;
  }

  &__items>.ColorPicker:not(.ColorPicker:last-child)>.ColorPicker__button {
    border-radius: 8px 0 0 8px;
  }


  &__items>.SettingsInput:last-child {
    border-radius: 0 8px 8px 0;
  }

  &__items_sub>.SettingsInput:last-child {
    padding-right: 9px;
  }

  &__lockBtn {
    outline: none;
    border: none;
    margin-left: 8px;
    width: 14px;
    padding: 0;
    background: transparent;
  }

  &__unlockIcon {
    stroke: #ffffff;
  }
}