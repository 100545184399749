@import './src/variables.scss';

.StyleTab {
  &__groupViewContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 110px;

    &_mockup {
      @media (max-width: map-get($grid-breakpoints, lg)) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-bottom: 10px;
      }
    }
  }

  &__subtabs {
    margin-bottom: 30px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      margin-bottom: 20px;
    }
  }

  &__note {
    margin: 0;
    color: map-get($fresh-theme-colors, 'grey');
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 10px;
      line-height: 16px;
    }
  }

  &__fieldFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
  }
}