@import './src/variables.scss';

.SelectButton {
  align-items: center;
  border-radius: 8px;
  border: none;
  color: #fff;
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  padding: 0;
  cursor: pointer;

  &__text {
    display: flex;
    // width: calc(100% - 32px);
  }

  &__arrow {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    width: 20px;
    margin-left: 12px;
    transition: 0.2s stroke ease;

    &_opened {
      stroke: map-get($fresh-theme-colors, 'pink');
    }
  }

  &:hover {
    .SelectButton__arrow {
      stroke: map-get($fresh-theme-colors, 'black');
    }
  }
}

.show {
  .SelectButton__arrow {
    stroke: map-get($fresh-theme-colors, 'pink');
  }
}