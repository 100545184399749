@import "./src/variables.scss";

.GroupListItem {
  position: relative;

  &__groupItem {
    height: 100%;

    .GroupSdkItem__imgContainer::after {
      box-sizing: content-box;
    }
  }

  &__editIcon {
    cursor: pointer;
    position: absolute;
    width: 26px;
    height: 26px;
    right: -4px;
    top: -4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    border-color: map-get($fresh-theme-colors, 'grey-light');
    background-color: #FFFFFF;
    transition: .2s all ease-in;
    opacity: 0;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 20px;
      height: 20px;
      border-width: 1px;

      svg {
        width: 10px;
        height: 10px;
      }
    }

    &_view {
      &_square {
        right: -2px;
        top: -4px;
      }

      &_circle {
        right: 0;
        top: 0;
      }

      &_bigSquare {
        right: 4px;
        top: -4px;
      }

      &_rectangle {
        right: 4px;
        top: -4px;
      }
    }

    &_visible {
      opacity: 1;
    }
  }
}

.GroupSdkItem_chosen .GroupSdkItem__imgContainer::after {
  border: 2px solid transparent;
  background: linear-gradient(-45deg, #B90AE0, #FF0198) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.GroupSdkItem_chosen.GroupSdkItem_view_circle,
.GroupSdkItem_chosen.GroupSdkItem_view_square {
  .GroupSdkItem__title {
    color: map-get($fresh-theme-colors, 'pink');
  }
}

.GroupSdkItem {
  &_view_rectangle {
    @media (max-width: map-get($grid-breakpoints, lg)) {
      height: 65px !important;
    }
  }
}

.GroupSdkItem__imgContainer_view_rectangle {
  height: 92px !important;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    width: 50px !important;
    height: 65px !important;
  }
}

.GroupSdkItem__img_view_rectangle {
  height: 84px !important;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    height: 58px !important;
    width: 44px !important;
  }
}

.GroupSdkItem__imgContainer_view_bigSquare {
  width: 90px !important;
  height: 90px !important;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    width: 66px !important;
    height: 66px !important;
  }
}

.GroupSdkItem__img_view_bigSquare {
  width: 82px !important;
  height: 82px !important;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    width: 58px !important;
    height: 58px !important;
  }
}

.GroupSdkItem__title_view_rectangle {
  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding-bottom: 8px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}

.GroupSdkItem_view_circle,
.GroupSdkItem_view_square {
  @media (max-width: map-get($grid-breakpoints, lg)) {
    height: auto !important;
  }
}

.GroupViewItem__imgContainer_type_bigSquare,
.GroupViewItem__imgContainer_type_rectangle {
  @media (max-width: map-get($grid-breakpoints, lg)) {
    width: 90%;
  }
}