.StoryEditorInner {
  width: 360px;
  height: 640px;

  position: relative;
  transform-origin: top left;
  overflow: hidden;
  background: transparent;
  z-index: 2;


  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &_large {
    height: 780px;
  }

  &__selectionArea {
    border: 2px solid #EB5757;
    background-color: rgba($color: #EB5757, $alpha: 0.4);
  }

  &__container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-height: 800px) {
    transform: scale(0.738);
  }
}