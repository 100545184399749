@import '/src/variables.scss';

.TalkAboutWidgetSettings {
  display: flex;

  &__col {
    align-items: center;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  &__line {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__input {
    width: 100% !important;
    font-size: 12px !important;
    line-height: 15px !important;
    padding: 10px !important;
    resize: none !important;
  }

  &__uploadBtn {
    border: none;
    background-color: map-get($theme-colors, 'black');
    padding: 7px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    outline: none;
    box-shadow: none;
  }

  &__uploadImgContainer {
    position: relative;
    border-radius: 50%;
    margin-right: 6px;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: map-get($theme-colors, 'grey');
      opacity: 0.6;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
      z-index: 1;
      border-radius: 50%;
    }
  }

  &__uploadImg {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__uploadText {
    color: $light-grey;
    font-size: 12px;
    line-height: 15px;
  }

  &__emptyImuploadImgIcon {
    width: 24px;
    height: 24px;
    margin-top: -1px;
  }

  &__iconEdit {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 2;
    width: 12px;
    height: 12px;
  }
}