@import 'src/variables.scss';

.GroupSettingsTab {
  &__formColumn {
    max-width: 480px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__formHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    gap: 20px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      margin-bottom: 20px;
    }
  }

  &__formHeaderContent {
    display: flex;
    align-items: center;
  }

  &__formHeaderTitleIcon {
    width: 32px;
    margin-left: 12px;
  }

  &__formHeaderTitle {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    color: map-get($fresh-theme-colors, 'black');
    margin: 0;
    word-break: keep-all;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  &__subTitle {
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    color: map-get($fresh-theme-colors, 'grey');
    margin-bottom: 32px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 24px;
    }
  }

  &__fieldGroup {
    width: 100%;
  }

  &__filedSet {
    margin-bottom: 32px;
    align-self: stretch;
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 32px;
    border-bottom: 1px solid #E9E6E9;

    &_noBorder {
      border: none;
      padding-bottom: 0;
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      margin-bottom: 24px;
      padding-bottom: 24px;
    }

    &_col {
      &_2 {
        display: grid;
        grid-template-columns: 80px 1fr;
        column-gap: 24px;

        @media (max-width: map-get($grid-breakpoints, lg)) {
          grid-template-columns: 64px 1fr;
          column-gap: 16px;
        }
      }

      &_twoEqual {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 16px;
      }

      &_twoEqualDesc {
        display: grid;
        grid-template-areas: "datepicker datepicker"
          "desc desc";
        grid-template-columns: repeat(2, 1fr);
        column-gap: 16px;
        row-gap: 12px;
      }

    }

    &_marginBottom {
      &_5 {
        margin-bottom: 5px;
      }
    }

    &_flex {
      &_start {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }


      &_between {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &_column {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

    }

    .form-group {
      margin-bottom: 0;
    }
  }

  &__filedSetDesc {
    grid-area: desc;
    font-size: 12px;
    line-height: 18px;
    color: map-get($fresh-theme-colors, 'grey');
    margin: 0;
  }

  &__datepicker {
    .input-input {
      font-size: 12px;
      line-height: 15px;
    }
  }

  &__switch {
    margin-right: 30px;
  }

  &__fieldsetLabel {
    font-size: 22px;
    font-weight: 600;
    line-height: 32px;
    color: map-get($fresh-theme-colors, 'grey');
    margin-bottom: 32px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 18px;
    }
  }

  &__link {
    color: map-get($fresh-theme-colors, 'pink');
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;

    &:hover,
    &:focus {
      color: map-get($fresh-theme-colors, 'pink');
      text-decoration: none;
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__fieldLabel {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');

    margin-bottom: 12px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 8px;
    }

    &_noMargin {
      margin-bottom: 0;
    }

  }

  &__field {
    margin-bottom: 32px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      margin-bottom: 24px;
    }
  }

  &__mainDesc {
    font-size: 14px;
    color: map-get($theme-colors, 'grey');
    margin-bottom: 24px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      margin-bottom: 16px;
    }
  }

  &__desc {
    font-size: 12px;
    line-height: 18px;
    margin-top: 12px;
    color: map-get($fresh-theme-colors, 'grey');
    margin-bottom: 0;
  }

  &__descGroup {
    margin-bottom: 12px !important;
  }
}