@import './src/variables.scss';

.SignInForm {
  &__btnIcon {
    width: 20px;
    height: 20px;
  }

  &__socialContainer {
    display: flex;
    margin-top: 40px;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 40px;
    border-bottom: 1px solid map-get($fresh-theme-colors, 'grey-light');
    margin-bottom: 40px;
  }
}