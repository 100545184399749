@import './src/variables.scss';

.EditorTemplatesTab {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  overflow-y: auto;

  @include use-dark-scroll;

  &__empty {
    width: 100%;
    margin-top: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $light-grey;
    font-size: 14px;
  }
}