@import 'src/variables.scss';

.ColorThemesItem {
  box-sizing: border-box;
  cursor: pointer;
  padding: 8px;
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;

  &_selected {
    background-color: #ff00d0;
  }

  &_dark {
    background-color: map-get($theme-colors, 'black');
  }

  &__color {
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }
}