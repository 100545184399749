@import '/src/variables.scss';

.StoryAnalyticsModal {
  &__title {
    color: map-get($fresh-theme-colors, 'black');
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 32px;
  }

  &__widgetContainer {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    width: 290px;
    margin: 0 auto;
    padding-top: 3px;
    padding-bottom: 3px;

    &_background {
      background: linear-gradient(rgb(206, 159, 252) 0%, rgb(115, 103, 240) 100%);
      padding: 10px 0;
      border-radius: 16px;
    }
  }

  &__widget {
    .StorySdk-QuestionWidget__question {
      color: map-get($fresh-theme-colors, 'black');
    }
  }

  &__widgetsStat {
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid #E9E6E9;
  }

  &__widgetsContainer {
    position: relative;
    margin-bottom: 32px;

    .swiper-wrapper {
      align-items: center;
    }
  }

  &__mainIndicatorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F8F7F8;
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 32px;

  }

  &__mainIndicatorValue {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    color: map-get($fresh-theme-colors, 'black');
    margin: 0;
  }


  &__mainIndicatorDesc {
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');
    margin: 0;
  }

  &__statListItem {
    display: flex;
    justify-content: space-between;
  }

  &__statListItem {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__statListItemEmoji {
    font-size: 24px;
    line-height: 24px;
    margin-right: 8px;
  }

  &__statListItemTitleContainer {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }

  &__statListItemTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');
    text-transform: capitalize;

    &_thin {
      font-weight: 400;
    }

    &_grey {
      color: #A49BA4;
    }

    &_emoji {
      font-size: 24px;
      line-height: 24px;
    }
  }

  &__statListItemValue {
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');


    &_grey {
      color: #A49BA4;
    }
  }


  &__statListItemValueAdditional {
    color: #A49BA4;
  }

  &__widgetsNav {
    border: none;
    background: transparent;
    outline: none;
    position: absolute;
    width: 36px;
    height: 36px;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 2;

    &_prev {
      right: auto;
      left: 0;
    }

  }

  &__widgetsNavIcon {
    &_next {
      transform: rotate(180deg);
    }
  }
}