@import "./src/variables";

.GroupSelector {
  position: relative;

  &__btn {
    display: flex;
    justify-content: space-between;
    height: 34px;
    align-items: center;
    padding: 0 12px;
    border-radius: 8px;
    background-color: map-get($theme-colors, 'black');
    border: none;
    width: 300px;
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
    max-width: 100%;
    overflow-x: hidden;
  }

  &__img {
    width: 20px;
    height: 20px;

    border-radius: 50%;
    margin-right: 8px;
  }

  &__text {
    font-size: 12px;
    line-height: 18px;
    word-break: keep-all;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
  }

  &__chevron {
    margin-right: 4px;
    margin-left: 10px;
  }

  &__menu {
    width: 300px;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    border-radius: 8px;
    background-color: #18182E;
    border: 1px solid #05051d;
  }

  &__group {
    display: block;
    border: none;
    background: none;
    padding: 12px;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    color: white;
    word-break: keep-all;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    width: 100%;

    &:hover,
    &_current {
      background: #05051d;
    }
  }

}