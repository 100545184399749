@import './src/variables.scss';

.StartStepsHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 45px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: map-get($grid-breakpoints, lg)) {
    position: static;
    transform: none;
    gap: 10px;
    margin-bottom: 10px;
  }

  &__step {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__stepTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'grey');
    transition: .225s ease all;
    white-space: nowrap;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
    }

    &_active {
      color: map-get($theme-colors, 'grey');
    }

    &_pink {
      color: map-get($fresh-theme-colors, 'pink');
    }
  }

  &__stepIcon {
    width: 16px;
    flex-shrink: 0;

    &_completed {
      width: 18px;
    }

    &_filled {
      fill: map-get($fresh-theme-colors, 'grey');

      &.StartStepsHeader__stepIcon_active {
        fill: map-get($theme-colors, 'grey');
      }
    }

    &_stroked {
      stroke: map-get($fresh-theme-colors, 'grey');

      &.StartStepsHeader__stepIcon_active {
        stroke: map-get($theme-colors, 'grey');
      }
    }
  }

  &__separatorIcon {
    stroke: map-get($fresh-theme-colors, 'grey-light');
    transition: .225s ease all;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 7px;
    }

    &_filled {
      stroke: map-get($theme-colors, 'grey');
    }

    &_pink {
      stroke: map-get($fresh-theme-colors, 'pink');
    }
  }
}