.CalendarTimeSelectOption {
  padding-top: 5px;
  padding-bottom: 5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #18182e;
  color: #d1cfd7;
  border: none;
  cursor: pointer;
  border: 1px solid transparent;

  &--selected {
    border-radius: 5px;
    border: 1px solid #fff731;
  }
}
