.SettingsSlider {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__slider {
    width: 115px;
    display: flex;
    align-items: center;
    height: 12px;
    margin: 0 10px;

    .slider {
      width: 100%;
    }
  }

  &__track {
    background: #ffffff;
    height: 2px;
    border-radius: 1px;
    margin-top: -1px;

    &_selected {
      background: #8B8B97;
    }
  }

  &__thumb {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #ffffff;
    display: inline-block;
    transform: translateY(-50%);
    cursor: grab;
  }

  &__title {
    color: #AFAFAF;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    margin: 0;
  }

  &__value {
    width: 22px;
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
  }
}