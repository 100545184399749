.TextWidgetInput {
  position: relative;
  overflow: hidden;
  height: 100%;

  &__container {
    position: relative;
    z-index: 1;

    &_gradient {
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent;
    }
  }

  &__textarea {
    border: none;
    width: 100%;
    height: 100%;
    resize: none;
    background: none;
    overflow: hidden;
    color: inherit;
    text-align: inherit;
    word-break: break-word;
    white-space: pre-line;
    padding: 0;
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    text-decoration: none;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}