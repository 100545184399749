@import './src/variables.scss';

.FreshInput {
  position: relative;
  display: block;
  width: 100%;

  &__input {
    display: block;
    width: 100%;
    padding: 14px 16px;
    border-radius: 12px;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'white');
    background: #FFFFFF;
    border: 1px solid map-get($fresh-theme-colors, 'grey-light');
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);

    @media (max-width: map-get($grid-breakpoints, lg)) {
      padding: 10px;
      font-size: 12px;
      line-height: 16px;
    }

    &::-webkit-search-cancel-button {
      position: relative;
      -webkit-appearance: none;
      height: 20px;
      width: 20px;
      background: url('../../../images/close-icon.svg') no-repeat center;
    }

    &_pointer {
      cursor: pointer;
    }

    &_theme {
      &_dark {
        background: map-get($theme-colors, 'black');
        border: 1px solid map-get($theme-colors, 'black');
        color: #FFFFFF;
      }
    }

    &_icon {
      &_left {
        padding-left: 48px;

        @media (max-width: map-get($grid-breakpoints, lg)) {
          padding-left: 32px;
        }
      }

      &_right {
        padding-right: 48px;

        @media (max-width: map-get($grid-breakpoints, lg)) {
          padding-right: 32px;
        }
      }
    }

    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 4px rgba(253, 25, 204, 0.15);
      border: 1px solid map-get($fresh-theme-colors, 'pink');
    }

    &_invalid {
      border: 1px solid map-get($fresh-theme-colors, 'red');
    }

    &::placeholder {
      color: map-get($fresh-theme-colors, 'grey');
      font-size: 14px;
      line-height: 20px;
      opacity: 1;
    }
  }

  &__icon {
    position: absolute;
    left: 16px;
    width: 20px;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 16px;
      left: 10px;
    }

    &_right {
      left: auto;
      right: 16px;

      @media (max-width: map-get($grid-breakpoints, lg)) {
        right: 10px;
      }
    }
  }
}