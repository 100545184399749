@import './src/variables.scss';

.EditorTemplateCard {
  background: map-get($theme-colors, 'black');
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  border-radius: 16px;
  padding: 10px;
  transition: .225s ease all;
  cursor: pointer;

  &__preview {
    display: flex;
    gap: 7px;
    margin-bottom: 10px;
  }

  &__previewItem {
    width: 58px;
    height: 103px;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    font-size: 0;
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    margin-bottom: 4px;
  }

  &__desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'grey');
    margin: 0;
  }
}