@import '/src/variables.scss';

.FreshTextarea {
  width: 100%;
  min-height: 80px;
  background: #FFFFFF;
  border-radius: 8px;
  color: map-get($fresh-theme-colors, 'white');
  padding: 12px;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid map-get($fresh-theme-colors, 'grey-light');
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &_marginBottom {
    margin-bottom: 16px;
  }

  @include use-light-scroll;

  &::placeholder {
    color: map-get($fresh-theme-colors, 'grey');
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 4px rgba(253, 25, 204, 0.15);
    border: 1px solid map-get($fresh-theme-colors, 'pink');
  }

  // &::-webkit-resizer {
  //   background: #FFFFFF;
  // }

}