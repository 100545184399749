.EmojiReactionWidgetSettings {
  width: max-content;

  &__body {
    display: flex;
    align-items: center;
  }

  &__divider {
    margin: -20px 20px;
    height: 76px;
    opacity: 0.1;
    border: 1px solid #FFFFFF;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    width: 100%;
    background: #05051D;
    border-radius: 8px;
    border: none;
    padding: 8px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 34px;

    .Drop, .Drop__toggle {
      width: 100%;
    }

    .Drop__toggle {
      height: 34px;
      padding: 8px !important;
    }

    &_add {
      justify-content: center;
      padding: 0;
    }

    &:hover {
      .EmojiReactionWidgetSettings__remove-btn {
        opacity: 1;
      }
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__itemEmoji {
    display: flex;
    align-items: center;
  }

  &__itemEmojiIconWrapper {
    display: flex;
    align-items: center;
    margin-right: 6px;

    .Drop__toggle {
      display: flex;
      align-items: center;
      padding: 0 !important;
      border: none !important
    }
  }

  &__itemEmojiTitle {
    margin: 0;
    text-transform: capitalize;
    font-size: 12px;
    line-height: 15px;
    color: #AFAFAF;
    word-break: break-word;
  }

  &__icon {
    fill: white;
    opacity: 0.6;
    width: 14px;
    height: 14px;
    display: block;
  }

  &__item:hover &__remove-btn {
    opacity: 1;
  }

  &__btn {
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin-right: 8px;
    width: 14px;
    height: 14px;
    border-radius: none;
  }

  &__addText {
    color: #AFAFAF;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
  }

  &__btnContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__remove-btn {
    display: flex;
    justify-content: center;
    background: transparent;
    padding: 0;
    width: 18px;
    height: 18px;
    border: none;
    opacity: 0;
    cursor: pointer;
  }

  &__remove-icon {
    width: 14.5px;
    height: 16px;
  }
}