.TimerWidgetSettings {
  display: flex;
  flex-direction: column;

  &__line {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__col {
    width: 100%;
    margin-right: 8px;
  }

  &__input {
    width: 100% !important;
    font-size: 12px !important;
    line-height: 15px !important;
    padding: 10px !important;
    resize: none !important;
  }

  &__timer {

    .input-input {
      font-size: 12px;
      line-height: 15px;
      height: 34px;
      border-radius: 8px;


    }

    .input-icon-wrapper {
      width: 34px !important;
      height: 34px !important;
    }

  }
}