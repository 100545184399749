@import './src/variables.scss';

.StoryScheduleModal {
  &__timeContainer {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 12px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      display: flex;
      flex-direction: column;
    }
  }

  &__desc {
    font-size: 12px;
    line-height: 18px;
    color: map-get($fresh-theme-colors, 'grey');
    margin: 0;
  }

  &__btnContaier {
    margin-top: 40px;
  }
}