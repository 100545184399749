@import './src/variables.scss';

.AddTemplatesModal {
  &__fieldSetTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');
    margin-bottom: 16px;
  }

  &__container {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 12px;

    @include use-light-scroll;
  }

  &__fieldSet {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}