@import 'src/variables.scss';

.HeaderButton {
  display: flex;
  align-items: center;
  height: 34px;
  padding: 0 14px;
  border-radius: 8px;
  color: #ffffff;
  border: none;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.7;
  }

  &__icon {
    margin-right: 8px;
  }

  &_color {
    &_primary {
      background-color: #05051D;
      font-size: 12px;
      line-height: 18px;
    }

    &_transparent {
      background-color: transparent;
    }

    &_transparent-white {
      background-color: transparent;

      .HeaderButton__icon {
        stroke: #FFFFFF;
      }
    }

    &_accent {
      background-color: #FF00D0;
      font-size: 12px;
      line-height: 18px;
    }

    &_transparent-black {
      background-color: transparent;
      color: map-get($theme-colors, 'grey');

      .HeaderButton__icon {
        stroke: map-get($theme-colors, 'grey');
      }
    }
  }
}