@import 'src/variables.scss';

.NavButton {
  color: map-get($fresh-theme-colors, 'black');
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  padding: 12px;
  padding-left: 32px;
  background: transparent;
  position: relative;
  display: flex;
  align-items: center;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    font-size: 12px;
    line-height: 16px;
    padding: 10px;
    padding-left: 24px;
  }

  &:focus,
  &:active,
  &:hover {
    color: map-get($fresh-theme-colors, 'black');
    text-decoration: none;
  }

  &__icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}