@import './src/variables.scss';

.MessagesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: 64px;
  right: 24px;
  z-index: 100002;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    right: 50%;
    transform: translateX(50%);
  }
}