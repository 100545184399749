@import './src/variables.scss';

.OrderSummaryCard {
  padding: 24px;
  border-radius: 12px;
  background: #FFFFFF;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.10), 0px 0px 1px rgba(0, 0, 0, 0.25), 0px 1px 0px rgba(0, 0, 0, 0.10);

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &_borderBottom {
      margin-bottom: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid map-get($fresh-theme-colors, 'grey-light');
    }
  }

  &__title {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    color: map-get($fresh-theme-colors, 'black');
    margin-bottom: 12px;
  }

  &__nextPayment {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: map-get($fresh-theme-colors, 'black');
    margin-bottom: 0;
  }

  &__cost {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    color: map-get($fresh-theme-colors, 'black');
    margin-bottom: 0;
  }

  &__pricing {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid map-get($fresh-theme-colors, 'grey-light');
  }

  &__pricingApps {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}