@import 'src/variables.scss';

.SettingsOpenLayerSelect {
  width: 100%;
  position: relative;
  background: map-get($theme-colors, 'black');
  border: 1px solid map-get($theme-colors, 'grey');
  padding: 4px;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  &__icon {
    width: 14px;
    height: 14px;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  &__btnsContainer {
    display: none;
    align-items: center;
    gap: 6px;
    padding: 6px 12px;
  }

  &__optionText {
    width: 100%;
    padding: 6px 12px;
    padding-right: 0px;
  }

  &__option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: none;
    text-align: left;
    color: #FFFFFF;
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-decoration: none;
    background: none;
    border-radius: 3px;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    &_current,
    &:hover {
      background: #FF00D0;
    }

    &:hover {
      .SettingsOpenLayerSelect__btnsContainer {
        display: flex;
      }
    }
  }
}