@import 'src/variables.scss';

.PlansCards {
  &__link {
    color: $pink;
    margin-bottom: 20px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;

    &:hover {
      color: $pink;
      text-decoration: none;
    }
  }

  &__headerDesc {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'pink');
    margin: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 12px;
  }

  &__desc {
    max-width: 454px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: map-get($fresh-theme-colors, 'black');
    margin: 0;
    margin-bottom: 44px;
  }

  &__cards {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__plansDesc {
    margin-top: 40px;
  }

  &__plansDescText {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    color: #A49BA4;
  }
}