@import './src/variables.scss';

.StoriesTemplatesContainer {
  background: #FFFFFF;
  border-radius: 16px;
  padding: 24px;
  padding-bottom: 40px;

  &__title {
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 24px;
    color: map-get($fresh-theme-colors, 'black');

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  &__empty {
    width: 174px;
    height: 309px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    overflow: hidden;
    border: 1px dashed #D2CDD1;
    text-align: center;
    color: #A49BA4;
    font-size: 14px;
  }
}