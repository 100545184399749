.EditorSidebar {
  display: flex;
  width: 100%;
  height: 100%;
  // background: #18182E;
  // border-radius: 16px;

  &__navigation {
    display: flex;
    flex-direction: column;
    width: 56px;
    height: 100%;
    background-color: #05051D;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    height: 56px;
    width: 56px;
    border-radius: 16px 0px 0px 16px;

    &_active {
      background-color: #18182E;
    }

    &:focus {
      outline: none;
    }
  }

  &__link_active &__icon {
    stroke: #FF00D0;

    &_filled {
      fill: #FF00D0;
      stroke: none;
    }
  }

  &__icon {
    stroke: #ffffff;

    &_filled {
      fill: #ffffff;
      stroke: none;
    }
  }

  &__main {
    display: none;
    max-width: 240px;
    width: 240px;

    &_show {
      display: block;
    }
  }

  &__tab {
    display: none;
    // height: calc(100vh - 58px);
    height: calc(100vh - 104px);

    &_current {
      display: block;
      background: #18182E;
      border-radius: 16px
    }

    &_index {
      &_0 {
        border-radius: 0px 16px 16px 16px;
      }
    }
  }
}