@import 'src/variables.scss';

.Output {
  color: $pink;
  font-size: 15px;
  line-height: 23px;
  width: 100%;
  border: 1px solid $pink;
  border-radius: 8px;
  padding: 5px 10px;

  &_copy {
    padding-right: 36px;
  }

  &__wrapper {
    width: 100%;
    position: relative;
  }

  &__copyBtn {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  &__copyText {
    font-size: 14px;
    margin: 0;
    position: absolute;
    right: -10px;
    transform: translateX(100%) translateY(-50%);
    top: 50%;
    color: $pink;
  }

  &__container {
    position: relative;
  }
}