@import './src/variables.scss';

.EditorTemplateItems {
  height: 100%;

  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
    row-gap: 12px;
    padding: 16px;
  }

  &__item {
    cursor: pointer;
    width: 96px;
    height: 170px;
    border-radius: 8px;
    overflow: hidden;
  }

  &__headerTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 16px 16px;
  }

  &__backButton {
    padding-left: 0 !important;
  }

  &__headerBottom {
    padding: 0 16px;
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    margin-bottom: 4px;
  }

  &__description {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'grey');
    margin: 0;
  }

  &__content {
    height: calc(100% - 62px);
  }
}