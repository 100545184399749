@import "./src/variables.scss";

.StartUseLayout {
  display: grid;
  grid-template-areas: "nav header"
    "nav content";
  row-gap: 24px;
  grid-template-columns: 240px minmax(1px, 1fr);
  grid-template-rows: 56px minmax(1px, 1fr);
  background: map-get($fresh-theme-colors, 'grey-background');

  .Header {
    @media (max-width: map-get($grid-breakpoints, lg)) {
      padding: 0;
    }

    &__container {
      @media (max-width: map-get($grid-breakpoints, lg)) {
        padding: 10px;
        height: auto;
      }
    }

    &__separator {
      @media (max-width: map-get($grid-breakpoints, lg)) {
        display: none;
      }
    }

    &__authControlDropdown {
      margin-top: 0;
    }

    &__authControl {
      font-size: 12px;
    }
  }


  &_noNav {
    height: 100%;
    grid-template-areas: "header"
      "content";
    grid-template-columns: 1fr;
    grid-template-rows: 56px minmax(1px, 1fr);
    padding-left: 24px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      padding-left: 0;
      display: flex;
      flex-direction: column;
    }
  }

  &__header {
    padding: 18px 24px;
    grid-area: header;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__content {
    grid-area: content;
  }

  &__side-nav {
    grid-area: nav;
  }

  &__addAppText {
    color: map-get($fresh-theme-colors, 'black');
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
  }

  &__loadContainer {
    background-color: #ffffff;
    padding: 24px;
    margin-right: 24px;
    border-radius: 16px;
    margin-bottom: 24px;
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding: 0 10px;
  }

}