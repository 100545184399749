.QuestionWidgetSettings {
    &__line {
        display: flex;
        flex-direction: column;
    }

    &__input {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }

}