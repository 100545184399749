.Drop {
    box-shadow: none;

    &__toggle {
        width: 100% !important;
        display: flex !important;
        align-items: center !important;
        outline: none;
        box-shadow: none !important;
        margin: 0;
        padding: 0 !important;
        height: auto;
        border: none !important;
    }

    &__dropdown {
        height: auto;
        background-color: transparent;
        outline: none;
        padding: 0 !important;
        margin: 0 !important;
        background: 0 !important;
        border: none !important;
        border-radius: 0 !important;
        z-index: 4000 !important;

        &_right {
            right: 0 !important;
            left: auto !important;
            transform: translate(0, 38px) !important;
        }
    }
}