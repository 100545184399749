@import 'src/variables.scss';

.CreateAppContainer {
  margin-bottom: 40px;

  &__labelContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &__labelInfo {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
    color: map-get($fresh-theme-colors, 'grey');
    transition: 0.3s ease all;

    &_red {
      color: map-get($fresh-theme-colors, 'red');
    }
  }

  &__lableTitle {
    margin: 0;
  }
}