@import './src/variables.scss';

.Tabs {
  &__tabsLine {
    display: flex;
    align-items: center;
  }

  &__tabsContainer {
    display: flex;
  }

  &__tab {
    display: block;
    padding: 18px 24px;

    border-radius: 16px 16px 0px 0px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'grey');
    text-align: left;
    border: none;
    background: transparent;

    transition: .225s all ease-in-out;


    @media (max-width: map-get($grid-breakpoints, lg)) {
      padding: 14px 10px;
      font-size: 10px;
      line-height: 14px;
    }

    &_current {
      background: #FFFFFF;
      color: map-get($fresh-theme-colors, 'black');
    }
  }


  &__tabPanel {
    display: none;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 24px;
    padding-bottom: 40px;

    &_index_0 {
      border-radius: 0px 16px 16px 16px;
    }

    &_onlyOne {
      border-radius: 16px;
    }

    &_current {
      display: block;
    }
  }

  &__tabContainer {
    margin-bottom: 40px;
  }

  &__tabsLineControl {
    margin-left: auto;
  }
}