@import './src/variables.scss';

.FreshCalendarHeader {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  padding-bottom: 20px;

  border-bottom: 1px solid #E9E6E9;

  &__arrow {
    width: 24px;
    height: 24px;
    transition: .225s ease all;

    &_next {
      transform: rotate(180deg);
    }
  }

  &__icon {
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 100%;
    justify-content: center;
    outline: none;
    padding: 0;

    &:hover {
      .FreshCalendarHeader__arrow {
        stroke: map-get($fresh-theme-colors, 'black');
      }
    }
  }

  &__month-name {
    line-height: 20px;
    padding: 0 8px;
    text-align: center;
    width: 200px;
    color: map-get($fresh-theme-colors, 'black');

    &_theme {
      &_dark {
        color: #FFFFFF;
      }
    }
  }
}