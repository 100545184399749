@import "./src/variables.scss";

.LocaleItem {
  display: flex;
  align-items: center;
  border: 1px solid #E9E6E9;
  box-sizing: border-box;
  border-radius: 12px;
  width: 320px;
  height: 48px;
  padding: 14px 16px;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    width: 100%;
    padding: 10px;
  }

  &:not(&:last-child) {
    margin-bottom: 12px;
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-right: 16px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      margin-right: 8px;
    }

    &>svg {
      width: 18px;
      height: 12px;

      @media (max-width: map-get($grid-breakpoints, lg)) {
        width: 16px;
        height: 10px;
      }
    }
  }

  &__title {
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');
    margin: 0;
    margin-right: 16px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      line-height: 16px;
      margin-right: 10px;
    }
  }

  &__defaultLabel {
    display: flex;
    align-items: center;
    padding: 4px 18px;

    background: #F8F7F8;
    border-radius: 16px;


    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #A49BA4;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      border-radius: 10px;
      padding: 4px 12px;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__actionsBtnWrapper {
    position: relative;
    margin-left: auto;
  }

  &__actionsBtn {
    border: none;
    outline: none;
    background: transparent;
  }

  &__actionsList {
    width: 160px;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 24px;
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    box-shadow: 0px 12px 24px rgba(42, 30, 42, 0.1);
    border-radius: 12px;
    background: #ffffff;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      left: auto;
      right: 0;
    }
  }

  &__acitonsListItem {
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    color: map-get($fresh-theme-colors, 'black');

    &:hover {
      content: "";
      text-decoration: none;
      color: map-get($fresh-theme-colors, 'pink');
    }

    &:not(:last-child) {
      margin-bottom: 16px;

      @media (max-width: map-get($grid-breakpoints, lg)) {
        margin-bottom: 12px;
      }
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      line-height: 14px;
    }
  }
}

.LocaleItemGroup {
  @media (max-width: map-get($grid-breakpoints, lg)) {
    width: 100%;
  }
}