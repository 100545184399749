@import "./src/variables.scss";

.AppStoriesRoute {
  &__groups {
    background: map-get($fresh-theme-colors, 'grey-background');
    margin-bottom: 24px;


  }

  &__tabs {
    padding-right: 24px;
  }

  &__tabsControl {
    display: flex;
    align-items: center;
    gap: 24px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      gap: 14px;
    }
  }

  &__unpublishedStoriesIcon {
    fill: map-get($fresh-theme-colors, 'pink');

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 12px;
    }
  }

  &__unpublishedStories {
    user-select: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'pink');

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 10px;
      line-height: 14px;
    }
  }
}