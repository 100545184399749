@import '/src/variables.scss';

.SettingsFontSelector {
  position: relative;
  width: 100%;

  &__control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 34px;
    padding: 0 12px;
    background: #05051D;
    border-radius: 8px;
    border: none;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    text-align: left;
  }

  &__toggleBtn {

  }

  &__title {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__menu {
    width: 100%;
    z-index: 1000;
    display: none;
    position: absolute;
    top: 0;
    left: -230px;
    flex-direction: column;
    border-radius: 8px;
    background-color: #18182E;
    border: 1px solid #05051d;
    box-sizing: border-box;
    overflow-y: auto;

    &_open {
      display: flex;
    }
  }

  &__input {
    font-size: 12px;
    line-height: 16px;
    padding: 12px 16px;
    border: none;
    background: transparent;
    color: white;

    &::placeholder {
      color: #AFAFAF;
    }

    &:focus {
      outline: none;
    }
  }

  &__list {
    padding: 8px;
    border-top: 1px solid #05051d;
  }

  &__scrollList {
    width: 100%;
  }

  &__option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px;
    border: none;
    text-align: left;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    text-decoration: none;
    background: none;
    border-radius: 4px;

    &:hover {
      background: #FF00D0;
    }
  }

  &__icon path {
    stroke: #FF00D0;
  }

  &__option:hover &__icon path {
    stroke: white;
  }

  &__plus {
    opacity: 0;
  }

  &__option:hover &__plus {
    opacity: 1;
  }
}