.StoryEditorSafeZones {
  &__background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    pointer-events: none;

    &_centered {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__backgroundMockTop {
    width: calc(100% - 20px);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    pointer-events: none;

    &_yellow {
      background: #8B8611;
    }

    &_transparent {
      background: transparent;
      z-index: 1;
    }
  }

  &__dynamicIslandImage {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &__backgroundMockTopTime {
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
  }

  &__backgroundMockTopIconWrapper {
    margin-right: 5px;
    height: 100%;
    position: relative;

    &:last-child {
      margin-right: 0;
    }
  }

  &__backgroundMockTopRightContainer {
    display: flex;
    align-items: flex-end;
  }

  &__backgroundMockTopIcon {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__backgroundMockBottom {
    width: calc(100% - 20px);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: transparent;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    pointer-events: none;

    &_yellow {
      background: #8B8611;
    }

    &_transparent {
      background: transparent;
    }
  }


  &__backgroundMockBottomImage {
    width: 100%;
    height: auto;

    &_hide {
      visibility: hidden;
    }
  }

  &__tooltip {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #ffffff;
    background: #EB5757;
    border-radius: 2px;
    padding: 2px 4px;
  }
}