@import 'src/variables.scss';

.SettingsDropdownButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 10px;
  height: 34px;
  background: #05051D;
  border-radius: 8px;
  border: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: $light-grey;

  &_disabled {
    opacity: 0.3;
  }

  &__icon {
    margin-right: 8px;

    & svg>path {
      stroke: #AFAFAF !important;
    }
  }

  &:hover {
    background: map-get($fresh-theme-colors, 'pink');
    color: #FFFFFF;

    & svg>path {
      stroke: #FFFFFF !important;
    }
  }

  &_disabled {
    &:hover {
      background: #05051D;
      color: $light-grey;

      & svg>path {
        stroke: #AFAFAF !important;
      }
    }
  }

  &__chevron {
    margin-left: auto;
  }

  &__titleContainer {
    cursor: pointer;

    &_disabled {
      cursor: auto;
    }
  }

  &__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__btn {
    cursor: pointer;

    &_disabled {
      cursor: auto;
    }
  }

  &__menu {
    width: 100%;
    z-index: 1000;
    display: none;
    position: absolute;
    bottom: 40px;
    left: 0;
    flex-direction: column;
    border-radius: 8px;
    background-color: #05051d;
    border: 1px solid #05051d;
    box-sizing: border-box;
    overflow-y: auto;

    &_open {
      display: flex;
    }
  }

  &__option {
    width: 100%;
    padding: 12px;
    border: none;
    text-align: left;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 12px;
    line-height: 15px;
    display: block;
    text-decoration: none;
    background: none;

    &_current,
    &:hover {
      background: #FF00D0;
    }
  }

  &__container {
    position: relative;
    width: 100%;
  }
}