@import 'src/variables.scss';

.DowngradeAppsItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;

  cursor: pointer;
  padding: 16px 24px;
  transition: 0.3s ease all;
  border-bottom: 1px solid #F8F7F8;

  &_selected,
  &:hover {
    background: #F8F7F8;
    border-radius: 12px;
  }

  &__emptyCircle {
    width: 18px;
    height: 18px;
    border: 2px solid map-get($fresh-theme-colors, 'grey');
    border-radius: 50%;
  }

  &__appSelect {
    width: 136px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__appTitle {
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__select {
    padding: 0;
  }

  &__emptyCircleContainer {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__storiesCount {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
  }

  &__storiesCountDesc {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    color: map-get($fresh-theme-colors, 'grey');
    margin: 0;
  }

  &__info {
    width: 152px;
  }
}