@import './src/variables.scss';

.FreshCalendar {
  font-size: 16px;
  list-style-type: none;
  margin: 0;
  background: #FFFFFF;
  border: 1px solid #E9E6E9;
  color: map-get($fresh-theme-colors, 'black');
  box-shadow: 0px 12px 24px rgba(42, 30, 42, 0.1);
  border-radius: 12px;

  &_theme {
    &_dark {
      background: map-get($theme-colors, 'black');
      border: 1px solid map-get($theme-colors, 'black');
      color: #FFFFFF;
    }
  }


  width: 284px;
  box-sizing: border-box;

  &_withTime {
    display: flex;
    flex-direction: column;

  }

  &__withoutBtn {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: transparent;
    border: none;
    outline: none;
    color: #2B1E2A;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    &_theme {
      &_dark {
        color: #FFFFFF;
      }
    }
  }

  &__bottomBtnContainer {
    justify-content: center;
    align-items: center;
    padding: 0 16px 16px;
  }


  &__timeContainer {
    display: grid;
    grid-template-columns: 116px 20px 116px;
    justify-content: center;
    align-items: center;
    padding: 0 16px 16px;
  }

  &__timeContainerDots {
    justify-self: center;
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');

    &_theme {
      &_dark {
        color: #FFFFFF;
      }
    }
  }

  &__timeInput {
    .FreshInput__input {
      padding: 8px 12px;
      border-radius: 8px;
      font-size: 14px;
      line-height: 20px;
      border: 1px solid map-get($fresh-theme-colors, 'grey-light');
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 15px;

    &_withTime {
      border-bottom: 1px solid #E9E6E9;
      margin-bottom: 16px;
    }
  }

  &__week {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2px;

    &-name {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 32px;

      color: #A49BA4;

      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
    }
  }

  &__day-empty {
    background: transparent;
    border: 1px solid #E9E6E9;
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
    color: #A49BA4;
    width: 32px;
    height: 32px;
    padding: 0;
    margin: 2px;
  }

  &__time {
    width: calc(100% - 280px);
  }
}