@import "./src/variables";

.WidgetList {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 12px 12px 12px 12px;
  height: 100%;
  position: relative;

  &__item {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
    border: none;
    padding: 0;
    background: none;

    &_disabled {
      opacity: 0.5;
      cursor: auto !important;
    }

    * {
      pointer-events: none;
    }

    .ImageLoaderWrapper {
      pointer-events: all;
    }

    &_filled {
      background: map-get($theme-colors, 'black');
      border-radius: 12px;
      padding: 10px 20px;
    }

  }
}