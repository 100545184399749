@import './src/variables.scss';

.StorySizeToogle {
  position: absolute;
  width: 232px;
  height: 38px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  top: -10px;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  border-radius: 20px;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;

    border-top: 7px solid #FFFFFF;
  }

  &__btn {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: transparent;
    padding: 0;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #05051D;
    transition: 0.3s ease all;

    &_active {
      color: #FFFFFF;
    }

    &_disabled {
      cursor: progress;
    }
  }

  &__icon {
    margin-right: 8px;
    fill: #05051D;
    transition: 0.3s ease all;

    &_white {
      fill: #FFFFFF;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: map-get($fresh-theme-colors, 'pink');
    border-radius: 20px;
    z-index: -1;
    transition: 0.3s ease all;

    &_right {
      transform: translateX(100%);
    }
  }
}