.SettingsAnswers {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__answerItem {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background: #05051D;
    border-radius: 8px;
    border: none;
    height: 34px;
    padding: 0 12px;

    &_marginBottomExpand {
      margin-bottom: 8px;
    }

    &_center {
      justify-content: center;
    }

    &:hover {
      .QuizOneAnswerWidgetSettings__removeBtn {
        opacity: 1;
      }
    }
  }

  &__leftAnswerChild {
    display: flex;
    align-items: center;
    margin-right: 6px;

    .Drop__toggle {
      display: flex;
      align-items: center;
      padding: 0 !important;
      border: none !important
    }
  }

  &__textarea {
    margin-bottom: 16px;
  }

  &__answerInput {
    display: block;
    background: none;
    border: none;
    word-wrap: break-word;
    margin: 0;
    color: #AFAFAF;
    width: 100%;
    font-size: 12px;
    line-height: 15px;
    padding: 9px 0;

    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
      border: none;
    }
  }

  &__addIcon {
    margin-right: 10px;
    fill: #FFFFFF;
    opacity: 0.6;
  }

  &__answerItem:hover &__removeBtn {
    opacity: 1;
  }

  &__removeBtn {
    display: flex;
    justify-content: center;
    background: transparent;
    padding: 0;
    width: 18px;
    height: 18px;
    border: none;
    opacity: 0;
    cursor: pointer;
  }

  &__removeIcon {
    width: 14.5px;
    height: 16px;
  }

  &__icon {
    fill: white;
    opacity: 0.6;
    width: 14px;
    height: 14px;
    display: block;
  }

  &__btn {
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin-right: 8px;
    width: 14px;
    height: 14px;
    border-radius: none;
  }

  &__addText {
    color: #AFAFAF;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
  }

  &__dropArea {
    width: 18px;
    height: 18px;
    border-radius: 2px;

    .FreshDropArea__image {
      border-radius: 2px;
    }
  }

  &__answerIdControl {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 18px;
    height: 18px;
    border-radius: 50px;
    margin-right: 10px;
    background: none;
    border: 1px solid #FFFFFF;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
    flex-shrink: 0;

    &_checked {
      border: none;
    }
  }
}