.CalendarHeader {
  display: flex;
  font-weight: 700;
  justify-content: space-between;
  padding: 0 0 10px;

  &__icon {
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 100%;
    justify-content: center;
    outline: none;
    padding: 0;

    &:hover {
      opacity: 0.7;
    }
  }

  &__month-name {
    line-height: 20px;
    padding: 0 8px;
    text-align: center;
    width: 200px;
  }
}
