.Button {
  background: #05051D !important;
  color: #fff !important;
}

.btn {
  --btn-height: 2.5rem;
  --btn-icon-size: 1.125rem;
  height: var(--btn-height);

  &.btn-with-icon {
    .btn-icon-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      width: var(--btn-height);
      height: var(--btn-height);
    }

    .btn-icon:not(.icon-caret) {
      width: var(--btn-icon-size);
      height: var(--btn-icon-size);
    }

    &.btn-icon-left {
      padding-left: var(--btn-height);
      padding-right: calc(var(--btn-height) / 2);

      .btn-icon-wrapper {
        left: 0;
      }
    }

    &.btn-icon-right {
      padding-right: var(--btn-height);
      padding-left: calc(var(--btn-height) / 2);

      .btn-icon-wrapper {
        right: 0;
      }
    }
  }

  &.btn-only-icon {
    width: var(--btn-height);
    height: var(--btn-height);
    padding-left: 0;
    padding-right: 0;

    .btn-icon-wrapper {
      left: 0;
      right: 0;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  &.btn-lg {
    --btn-height: 4.25rem;
    --btn-icon-size: 1.125rem;
  }

  &.btn-sm {
    --btn-height: 1.875rem;
    --btn-icon-size: 1.125rem;
  }

  &.btn-xs {
    --btn-height: 2.125rem;
    --btn-icon-size: 0.875rem;
  }
}
