@import 'src/variables.scss';

.StoryPreview {
  width: 360px;
  height: 640px;

  position: relative;
  background-position: center;
  background-size: cover;
  transform-origin: top left;
  flex-shrink: 0;
  user-select: none;
  pointer-events: none;

  &_large {
    height: 780px;
  }

  &__wrapper {
    position: relative;
  }

  &_hidden {
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgba($color: map-get($theme-colors, 'grey'), $alpha: 0.8);
      top: 0;
      left: 0;
      z-index: 9999;
    }
  }

  &_dashboardVariant {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.65, 0.648);
    transform-origin: center;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      transform: translate(-50%, -50%) scale(0.5);
    }
  }

  &_templateVariant {
    top: 50%;
    left: 50%;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      transform: translate(-50%, -50%) scale(0.5);
    }
  }

  &__widget {
    position: absolute;
  }

  &__video {
    width: 100%;
    height: 100%;
  }
}