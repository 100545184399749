.WidgetLine {
  position: absolute;
  width: 2px;
  background-color: #EB5757;
  z-index: 9999;

  &__value {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #FFFFFF;
    font-size: 12px;
    background: #EB5757;
    border-radius: 2px;
    transform: translateX(-50%) translateY(-50%);
  }
}