@import '/src/variables.scss';

.SwitchControl {
  &__switch {
    min-height: 12px !important;
    height: 12px !important;
    padding-left: 0 !important;
    padding-right: 20px !important;

    .custom-control-label {
      cursor: pointer !important;

      &::before {
        background-color: $light-grey !important;
        border-radius: 8px !important;
        border: none !important;
        width: 20px !important;
        height: 12px !important;
        top: 0 !important;
        left: 0 !important;
      }

      &::after {
        width: 8px !important;
        height: 8px !important;
        background-color: map-get($theme-colors, 'white') !important;
        border-radius: 50% !important;
        top: 2px !important;
        left: 2px !important;
      }
    }

    &_disabled {
      .custom-control-label {
        cursor: auto !important;
      }
    }

    .custom-control-input~.custom-control-label::before {
      box-shadow: none !important;
    }

    .custom-control-input:checked~.custom-control-label::before {
      color: #fff !important;
      border-color: transparent !important;
      background-color: $pink !important;
    }

    .custom-control-input:disabled~.custom-control-label::before {
      background-color: $light-grey !important;
    }


    .custom-control-input {
      width: 20px !important;
      height: 12px !important;
    }

    .custom-control-input:checked~.custom-control-label::after {
      transform: translateX(0.5rem) !important;
    }
  }
}