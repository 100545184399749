.input-container {
  --input-height: 2.5rem;

  .input-input {
    height: var(--input-height);
    padding-left: .75rem;
    padding-right: .75rem;
    background: #05051D;
    border: 1px solid #05051D;
    color: #fff;
    border-radius: 4px;

    &:focus {
      box-shadow: 0 0 6px 0px #FF00D0 !important;
      border: 1px solid #FF00D0 !important;
      background: #05051D;
      color: #fff;
    }

    // &::placeholder {
    //   color: #fff;
    // }


    &.text-black {
      color: #05051D;

      // &::placeholder {
      //   color: #05051D;
      // }

      &:focus {
        color: #05051D;
      }
    }
  }

  &.has-icon {
    .input-icon-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      min-width: var(--input-height);
      height: var(--input-height);

      &:focus {
        outline: none;
      }
    }
  }

  &.input-icon-left {
    .input-input {
      padding-left: var(--input-height);
    }

    .input-icon-wrapper {
      left: 0;
    }
  }

  &.input-icon-right {
    .input-input {
      padding-right: var(--input-height);
    }

    .input-icon-wrapper {
      right: 0;
    }
  }

  &.is-textarea {
    .input-input {
      height: auto;
      min-height: var(--input-height);
    }
  }
}
