@import "/src/variables.scss";

.AppIntegrationModal {
  &__input {
    margin-bottom: 16px;
  }

  &__description {
    padding: 12px;
    margin-bottom: 16px;
    border-radius: 4px;
    background: map-get($fresh-theme-colors, 'grey-background');
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}