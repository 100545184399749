@import 'src/variables.scss';

.AccountSettingsTab {
  &__link {
    color: $pink;
    margin-bottom: 20px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;

    &:hover {
      color: $pink;
      text-decoration: none;
    }
  }

  &__locales {
    width: 100%;
    height: 122px;
  }

  &__currentLocale {
    display: flex;
    align-items: center;
  }

  .FreshDrop__dropdown {
    width: 100%;
  }
}