@import 'src/variables.scss';

.BackgroundColorTemplates {
  &__btn {
    display: flex;
    align-items: center;
    margin: 0 auto;
    gap: 8px;
    background: map-get($theme-colors, 'grey');
    padding: 9px 14px;
    border-radius: 12px;
    cursor: pointer;
    margin-bottom: 16px;
  }

  &__icon {
    width: 20px;
    height: 20px;
  }

  &__text {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    color: #FFFFFF;
  }

  &__colorExample {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: none;

    .ColorExample__mainColor {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }

    .ColorExample__background {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }

  &__colorsWrapper {
    .simplebar-content {
      display: grid !important;
      justify-content: flex-start !important;
      gap: 7px;
      grid-template-columns: repeat(8, 20px);
    }

    .simplebar-track.simplebar-vertical {
      width: 8px;
    }

    .simplebar-scrollbar:before {
      background: #3f3f5c;
      border-radius: 10px;
    }

    .simplebar-content:after,
    .simplebar-content:before {
      display: none;
    }
  }
}