.Checkbox {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  &__checkbox {
    opacity: 0;
    position: absolute;
    cursor: pointer;
  }

  &__customCheckbox {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    width: 18px;
    height: 18px;
    background: linear-gradient(360deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #F2F2F2;
    border: 1px solid rgba(0, 0, 0, 0.11);
    cursor: pointer;

    &_checked {
      color: #FF00D0;
    }

    & > svg {
      font-size: 14px;
    }
  }

  &__label {
    margin-left: 16px;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;

    a {
      color: #FFFFFF;
    }
  }
}
