@import 'src/variables.scss';

.Datepicker {
  position: relative;

  &__dropdown {
    position: absolute;
    z-index: 2;

    &_left {
      right: 0;
    }
  }

  &__icon {
    stroke: $pink ;
  }

  &__input {
    &_range * {
      text-transform: uppercase;
    }
  }
}