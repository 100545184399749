.StoryLogicEmptyMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 8px;
  }

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #A49BA4;
    margin-bottom: 24px;
  }

  &__img {
    width: 184px;
    height: auto;
  }
}