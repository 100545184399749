@import './src/variables.scss';

.AppOrderCard {
  padding: 24px;
  border-radius: 12px;
  background: #FFFFFF;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.10), 0px 0px 1px rgba(0, 0, 0, 0.25), 0px 1px 0px rgba(0, 0, 0, 0.10);

  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding: 14px;
  }

  &__title {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    color: map-get($fresh-theme-colors, 'black');
    margin: 0;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__titleContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    gap: 12px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      gap: 8px;
      margin-bottom: 12px;
    }
  }

  &__actionContainer {
    margin-bottom: 24px !important;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      margin-bottom: 16px !important;
    }
  }

  &__desc {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin: 0;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
  }

  &__infoItemLink {
    cursor: pointer;
    margin-left: 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: map-get($fresh-theme-colors, 'pink');

    &:hover {
      text-decoration: none;
      color: map-get($fresh-theme-colors, 'pink');
    }
  }

  &__infoItemContainer {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &__infoItem {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
  }

  &__infoItemValue {
    font-weight: 700;
  }

  &__price {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: map-get($fresh-theme-colors, 'black');
    margin-bottom: 3px;
  }

  &__priceDesc {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: map-get($fresh-theme-colors, 'black');
    margin-bottom: 24px;
  }

  &__button {
    width: 100%;
    max-width: 232px;
  }

  &__activeLabel {
    padding: 4px 12px;
    background-color: map-get($fresh-theme-colors, 'pink');
    border-radius: 12px;
    color: #FFFFFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  &__buttonContainer {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__limitsText {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      line-height: 16px;
    }
  }
}