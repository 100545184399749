@import "/src/variables.scss";

.AppIntegrationsCard {
  width: 300px;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.25), 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
  padding: 16px;

  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 0;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
  }

  &__title {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: map-get($theme-colors, 'black');
  }

  &__top {
    border-bottom: 1px solid map-get($fresh-theme-colors, 'grey-light');
  }

  &__label {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: map-get($fresh-theme-colors, 'grey-background');
    padding: 2px 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'grey');
  }

  &__indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #EB5757;

    &_active {
      background: #00CC66;
    }
  }

  &__button {
    width: auto;
  }
}