.StoryPreviewIcon {
  &__storyImage {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    margin-right: 8px;
    border: 1px solid #E9E6E9;
  }

  &__storyImageIcon {
    position: absolute;
    top: -50%;
  }
}