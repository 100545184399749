@import "./src/variables";

.StoryEditor {
  position: relative;

  * {
    user-select: none;
  }

  &__mockup {
    position: relative;
    width: 100%;
    height: auto;
    pointer-events: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  &__protector {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    pointer-events: none;

    &_allowEvents {
      pointer-events: all;
      z-index: 3;
    }
  }

  &__backFilling {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 98%;
    height: 98%;
    background: #000000;
    pointer-events: none;
  }

  &__sizeToogle {
    width: 200px;
    height: 50px;
    position: absolute;
    top: -50px
  }

  &__noBackground {
    position: absolute;
    width: 247px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__noBackgroundTitile {
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 12px;
  }

  &__noBackgroundSelect {
    padding: 16px;
    border-radius: 8px;
    background-color: map-get($theme-colors, 'black');
    margin-bottom: 12px;
  }

  &__chooseTemplateBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 12px;
    border-radius: 8px;
    background-color: map-get($theme-colors, 'black');
    padding: 12px;
    margin: 0 auto;
  }

  &__chooseTemplateText {
    color: #FFFFFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}