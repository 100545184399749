@import "/src/variables.scss";

.GroupResultsAnalytics {
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    color: map-get($fresh-theme-colors, 'black');
    margin-bottom: 30px;
  }

  &__container {
    display: flex;
    width: 100%;
    padding-bottom: 41px;
    overflow-x: auto;
    @include use-light-scroll;
  }

  &__layer {
    &:first-child {
      margin-left: auto;
    }

    &:last-child {
      margin-right: auto;
    }
  }
}