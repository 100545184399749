.CreateOnboardingModal {
  &__dropArea {
    position: relative;
    width: 96px !important;
    height: 96px !important;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  &__btn {
    margin-top: 40px;
  }

  &__image {
    width: 96px;
    height: 96px;
    border-radius: 12px;
    border: 2px solid #FD19CC;
    padding: 2px;
    object-fit: cover;
  }

  &__imageContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }
}