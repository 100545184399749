@import "./src/variables.scss";

.StoryHiddenMessage {
  width: calc(100% + 1px);
  height: 100%;
  position: absolute;
  background: rgba($color: map-get($theme-colors, 'grey'), $alpha: 0.8);
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  pointer-events: none;

  &__icon {
    stroke: #fff;
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    text-align: center;
    color: map-get($fresh-theme-colors, 'grey');
  }

  &__circle {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: map-get($fresh-theme-colors, 'grey');
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}