.Picker {
  &__saturation {
    position: relative;
    margin-bottom: 12px;
    width: 214px;
    height: 141px;
    border-radius: 3px;
    overflow: hidden;
  }

  &__hue,
  &__alpha {
    width: 214px;
    position: relative;
    height: 12px;
    margin-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;
  }

  &__pointer {
    width: 10px;
    height: 10px;
    border: 2px solid #FFFFFF;
    border-radius: 7px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 1);

    &_saturation {
      transform: translate(-5px, -5px);
    }

    &_slider {
      transform: translateX(-50%) translateY(1px);
    }
  }

  &__input {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 8px;
  }

  &__input input {
    text-align: center;
  }

  &__colorsTitle {
    color: white;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 10px;
  }

  &__inputItemContainer {
    display: flex;
    background: #05051D;
    border-radius: 4px;
    padding: 0 4px;
  }

  &__inputItem {
    background: transparent;
    outline: none;
    box-shadow: none;
    width: 100%;
    border: none;
    color: white;
    font-size: 12px;
    line-height: 15px;
    background: #05051D;
    border-radius: 4px;

    &_wide {
      min-width: 50px;
    }

    &:last-child {
      border-right: none;
    }
  }

  &__inputItemPercent {
    padding: 0 4px;
    width: auto;
    height: auto;

    .SettingsInput__postfix {
      margin-left: 0;
    }
  }

  &__mode {
    display: flex;
    align-items: center;
    padding: 10px;
    border: none;
    background: none;
    font-size: 12px;
    line-height: 15px;
    color: white;
    background: #05051D;
    border-radius: 4px;
  }

  &__colors {
    margin-right: -7px;
  }

  &__modeVal {
    margin-right: 6px;
  }

  &__modeValIcon {
    fill: #AFAFAF;
  }

  &__colorsWrapper {
    .simplebar-content {
      display: grid !important;
      justify-content: flex-start !important;
      gap: 8px;
      grid-template-columns: repeat(7, 24px);
    }

    .simplebar-track.simplebar-vertical {
      width: 8px;
    }

    .simplebar-scrollbar:before {
      background: #3f3f5c;
      border-radius: 10px;
    }

    .simplebar-content:after,
    .simplebar-content:before {
      display: none;
    }
  }
}