@import './src/variables.scss';

.Input {
  position: relative;
  display: block;
  width: 100%;

  &__input {
    display: block;
    width: 100%;
    padding: 14px 16px;
    border-radius: 12px;
    font-size: 14px;
    line-height: 20px;

    &:focus {
      outline: none !important;
      box-shadow: 0px 0px 0px 4px rgba(253, 25, 204, 0.15) !important;
      border: 1px solid map-get($fresh-theme-colors, 'pink') !important;
    }

    &_invalid {
      // box-shadow: 0 0 6px 0px #FF00D0 !important;
      border: 1px solid map-get($fresh-theme-colors, 'red') !important;
    }

    &_theme {
      &_dark {
        color: white;
        background: #05051D;
        border: 1px solid rgba(0, 0, 0, 0.11);

        // &::placeholder {
        //   color: white;
        // }

        &:focus {
          background: #05051D;
          color: #fff;
        }
      }

      &_light {
        color: map-get($fresh-theme-colors, 'white');
        background: #FFFFFF;
        border: 1px solid #E9E6E9;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);

        &::placeholder {
          color: map-get($fresh-theme-colors, 'grey');
          font-size: 14px;
          line-height: 20px;
          opacity: 1;
        }
      }
    }
  }

  &__rightControls {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__control {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    width: 20px;
    height: 20px;
    padding: 0;
  }

  &__controlIcon {
    &:hover {
      stroke: map-get($fresh-theme-colors, 'black');
    }
  }
}
