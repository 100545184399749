.sidemenu-container {
  left: 0;
  top: var(--header-height);
  height: calc(100vh - var(--header-height));
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 11112;
  width: var(--sidemenu-width);
}

.sidemenu-submenu-container {
  left: var(--sidemenu-width);
  top: var(--header-height);
  height: calc(100vh - var(--header-height));
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 11112;
  width: var(--submenu-width);
}
