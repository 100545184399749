@import "./src/variables.scss";

.AccountModuleLayout {
  display: grid;
  grid-template-areas: "nav header"
    "nav content";
  row-gap: 24px;

  grid-template-columns: 240px minmax(1px, 1fr);
  grid-template-rows: 56px minmax(1px, 1fr);
  background: map-get($fresh-theme-colors, 'grey-background');

  @media (max-width: map-get($grid-breakpoints, lg)) {
    grid-template-columns: 1fr;
    grid-template-areas: "header"
      "content";
    padding-left: 0;
    grid-template-rows: auto;
  }

  &_noNav {
    height: 100%;
    grid-template-areas: "header"
      "content";
    grid-template-columns: 1fr;
    padding-left: 24px;
  }

  &__header {
    padding: 18px 24px;
    grid-area: header;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }
  }

  &__content {
    grid-area: content;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 100%;
      overflow: hidden;
    }
  }

  &__side-nav {
    grid-area: nav;
  }
}