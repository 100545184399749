@import "./src/variables.scss";

.StoryLimitedWidgets {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 12px;
  background: map-get($fresh-theme-colors, 'grey-background');

  &_editor {
    width: calc(100% + 1px);
    height: 100%;
    position: absolute;
    background: rgba($color: map-get($theme-colors, 'grey'), $alpha: 0.8);
    top: 0;
    left: 0;
    z-index: 9999;
    padding: 20px;
    pointer-events: painted;
  }

  &__text {
    text-align: center;
    color: map-get($fresh-theme-colors, 'grey');
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
  }

  &__button {
    width: 130px;
  }
}