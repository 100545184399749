@import './src/variables.scss';

.AppAnalyticsRoute {
  // background-color: #ffffff;
  // padding: 24px;
  margin-right: 24px;
  // border-radius: 16px;

  // @media (max-width: map-get($grid-breakpoints, lg)) {
  //   min-height: 500px;
  // }
}