@import './src/variables.scss';

.FooterLinks {
  display: flex;
  align-items: center;

  &__link {
    color: map-get($fresh-theme-colors, 'grey');
    text-decoration: none;
    font-size: 14px;
    line-height: 20px;
    padding: 0 16px;
    border-right: 1px solid #E9E6E9;

    &:last-child {
      border: none;
    }

    &:hover {
      color: map-get($fresh-theme-colors, 'pink');
      text-decoration: none;
    }
  }
}