.SettingsBox {
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #18182E;
  border-left: 1px solid #05051D;
  // padding-bottom: 150px;
  min-height: calc(100vh - 104px);
  border-radius: 16px;

  &__actionBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #A49BA4;
    border-radius: 2px;
    width: 14px;
    height: 14px;
    color: #FFFFFF;
    padding: 0;
  }

  &__titleMain {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 16px;
    padding-bottom: 0;
  }

  &__titleContainer {
    display: flex;
    margin-bottom: 8px;
    justify-content: space-between;
    align-items: center;

    &_noMargin {
      margin: 0;
    }
  }

  &__title {
    color: #AFAFAF;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
  }

  &__group {
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    border-bottom: 1px solid #05051D;

    &_noPadding {
      padding: 0;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__groupContent {
    display: flex;
    flex-direction: column;
    height: auto;
    opacity: 1;

    &_gap {
      gap: 8px;
    }

    &_hidden {
      height: 0;
      opacity: 0;
      visibility: collapse;
    }
  }

  &__field {
    width: 100%;
    line-height: 0;

    &_separate {
      display: grid;
      grid-template-columns: repeat(2, 88px);
      gap: 8px;
    }

    &:not(&:last-child) {
      margin-bottom: 8px;
    }
  }

  &__switchContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 11px;

    &_noMargin {
      margin-bottom: 0;
    }
  }
}