@import 'src/variables.scss';

.FreshAccordion {
  background: #F8F7F8;
  border-radius: 12px;
  padding: 14px 16px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &_showed {
      margin-bottom: 24px;
    }

    &_noClickable {
      cursor: default;
    }
  }

  &__titleColumn {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 14px;
    line-height: 20px;
    color: map-get($fresh-theme-colors, 'black');
  }

  &__icon {
    width: 20px;
    margin-right: 12px;
  }

  &__body {
    display: none;

    &_showed {
      display: block;
    }
  }

  &__switch {
    .custom-control-label {
      cursor: pointer !important;

      &::before {
        background-color: map-get($theme-colors, 'black') !important;
        border-radius: 17px !important;
        border: none !important;
        width: 36px !important;
        height: 24px !important;
        top: 0 !important;
      }

      &::after {
        width: 20px !important;
        height: 20px !important;
        background-color: map-get($theme-colors, 'white') !important;
        border-radius: 50% !important;
        top: 2px !important;
      }
    }

    .custom-control-input~.custom-control-label::before {
      box-shadow: none !important;
    }

    .custom-control-input:checked~.custom-control-label::before {
      color: #fff !important;
      border-color: transparent !important;
      background-color: $pink !important;
    }

  }
}