@import './src/variables.scss';

.SetupTab {
  &__link {
    display: inline-block;
    margin-bottom: 20px;
  }

  &__labelIcon {
    margin-right: 8px;
  }

  &__groupViewContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 110px;
  }

  &__subtabs {
    margin-bottom: 16px;
  }

  &__desc {
    margin-top: 7px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #A49BA4;
    max-width: 851px;
    margin-bottom: 30px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      margin-bottom: 20px;
      font-size: 10px;
    }
  }

  &__descLink {
    color: map-get($fresh-theme-colors, 'pink');
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: map-get($fresh-theme-colors, 'pink');
      text-decoration: none;
    }
  }

  &__note {
    max-width: 851px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 8px 11px;
    border-radius: 3px;
    background-color: rgba($color: map-get($fresh-theme-colors, 'pink'), $alpha: 0.1);
    color: map-get($fresh-theme-colors, 'black');

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
    }
  }
}