@import 'src/variables.scss';

.SelectDropdownItem {
  align-items: center;
  color: map-get($fresh-theme-colors, 'black');
  border: none;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  width: 100%;
  padding: 15px 16px;
  font-size: 14px;
  line-height: 20px;

  &:hover {
    background-color: #F8F7F8;
  }

  &--active {
    background-color: #F8F7F8;
  }
}