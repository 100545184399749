@import 'src/variables.scss';

.DowngradeModal {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    color: map-get($fresh-theme-colors, 'black');
  }

  &__desc {
    font-size: 12px;
    font-style: normal;
    line-height: 18px;
    color: map-get($fresh-theme-colors, 'black');
    text-align: center;
    max-width: 454px;
    margin-bottom: 0;

    &_bold {
      font-weight: 700;
    }
  }

  &__descContainer {
    margin-bottom: 40px;
  }

  &__apps {
    width: 100%;
  }

  &__apps {
    margin-bottom: 40px;
  }

  .simplebar-scrollbar:before {
    background: #E9E6E9;
    border-radius: 10px;
  }
}