.AppSettingsRoute {
  // &__header {
  //   background: #18182E;
  //   height: 80px;
  // }

  &__tabs {
    padding-right: 24px;
  }
}
