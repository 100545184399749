@import 'src/variables.scss';

.FreshDatepicker {
  position: relative;
  flex-shrink: 0;

  &__dropdown {
    position: absolute;
    z-index: 3;

    &_left {
      right: 0;
    }
  }

  &__icon {
    &_pink {
      stroke: map-get($fresh-theme-colors, 'pink');
    }
  }

  &__label {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
    color: map-get($fresh-theme-colors, 'black');

    &_theme {
      &_dark {
        color: #FFFFFF;
      }
    }
  }
}