.CalendarTimeSelect {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  width: 50%;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0;
  }

  &__item {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #18182e;
    color: #d1cfd7;
    border: none;
    cursor: pointer;

    &--selected {
      border-radius: 5px;
      border: 1px solid #fff731;
    }
  }
}
