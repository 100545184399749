@import 'src/variables.scss';

.SettingsLayerSelect {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__headerText {
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #A49BA4;

    &_uppercase {
      text-transform: uppercase;
    }
  }

  &__scoreText {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #A49BA4;
  }

  &__answerContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  &__scoreInput {
    width: 20px;
    background: transparent;
    border: none;
    outline: none;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: end;
    text-transform: uppercase;
  }

  &__scoreInputContainer {
    display: flex;
    align-items: center;
    padding-right: 12px;
  }

  &__layers {
    width: 100%;
    position: relative;
    background: map-get($theme-colors, 'black');
    border: 1px solid map-get($theme-colors, 'grey');
    padding: 4px;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  &__layerItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: none;
    text-align: left;
    text-decoration: none;
    background: none;
    border-radius: 3px;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    &_current,
    &:hover {
      background: #FF00D0;
    }
  }

  &__layerTitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    flex-grow: 1;
    cursor: pointer;
    padding: 6px 12px;
    padding-right: 0;
  }

  &__scoreInputText {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
  }
}