.ChooseAnswerWidgetSettings {
  &__answerItem {
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 8px;
    align-items: center;
    justify-content: space-between;
    background: #05051D;
    border-radius: 8px;
    border: none;
    height: 34px;
    padding: 0 12px;

    &_center {
      justify-content: center;
    }

    &_marginBottomExpand {
      margin-bottom: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      .ChooseAnswerSettings__removeBtn {
        opacity: 1;
      }
    }
  }

  &__textarea {
    margin-bottom: 16px;
  }

  &__answerInput {
    display: block;
    background: none;
    border: none;

    margin: 0;
    color: #AFAFAF;


    width: 100% !important;
    font-size: 12px !important;
    line-height: 15px !important;
    padding: 9px 0 !important;

    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
      border: none;
    }
  }

  &__answerIdControl {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 18px;
    height: 18px;
    border-radius: 50px;
    margin-right: 10px;
    background: none;
    border: 1px solid #FFFFFF;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
    flex-shrink: 0;

    &_checked {
      border: none;
    }
  }

  &__row {
    display: flex;
    margin-bottom: 12px;
  }

  &__textWrapper {
    width: 100%;
    margin-right: 12px;
  }

  &__text {
    width: 100% !important;
    font-size: 12px !important;
    line-height: 15px !important;
    padding: 10px !important;
    resize: none;
  }

  &__addIcon {
    margin-right: 10px;
    fill: #FFFFFF;
    opacity: 0.6;
  }

  &__newAnswerTitle {
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    opacity: 0.6;
  }

  &__answerItem:hover &__removeBtn {
    opacity: 1;
  }

  &__removeBtn {
    display: flex;
    justify-content: center;
    background: transparent;
    padding: 0;
    width: 18px;
    height: 18px;
    border: none;
    opacity: 0;
    cursor: pointer;
  }

  &__removeIcon {
    width: 14.5px;
    height: 16px;
  }

  &__icon {
    fill: white;
    opacity: 0.6;
    width: 14px;
    height: 14px;
    display: block;
  }

  &__btn {
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin-right: 8px;
    width: 14px;
    height: 14px;
    border-radius: none;
  }

  &__addText {
    color: #AFAFAF;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
  }

  &__markCorrectAnswer {
    margin-top: 8px;
  }
}