@import 'src/variables.scss';

.PieChart {
  width: 100%;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    flex-wrap: wrap;
  }

  &__legend {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 50%;
    flex-shrink: 0;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      gap: 6px;
      max-width: 100%;
    }
  }

  &__legendItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    transition: 0.3s ease all;
    border: 2px solid transparent;
    padding: 4px;
    border-radius: 4px;

    &_hovered {
      border-color: map-get($fresh-theme-colors, 'pink');
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      border: 1px solid transparent;
      padding: 2px;
    }
  }

  &__legendItemContainer {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__legendColor {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    flex-shrink: 0;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 14px;
      height: 14px;
    }
  }

  &__legendLabel {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      line-height: 14px;
    }
  }

  &__legendValue {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 12px;
      line-height: 14px;
    }
  }

  &__chart {
    display: flex;
    justify-content: center;
    flex-grow: 1;
  }
}