@import './src/variables.scss';

.AppSettingsTab {
  &__link {
    display: inline-block;
    margin-bottom: 20px;
  }

  &__labelIcon {
    margin-right: 8px;
  }

  &__groupViewContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 110px;
  }

  &__labelContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }
}