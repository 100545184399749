body,
#root {
  width: 100%;
  height: 100vh;
  font-family: 'Inter', Helvetica, sans-serif;
}

*:focus {
  outline: 0;
  outline: none;
}

body {
  background: #F8F7F8;
}

.text-pink {
  color: #ff00d0;
}

.text-opacity-50 {
  opacity: 0.5;
}

.mr-0_5 {
  margin-right: 0.3rem;
}

.border-danger-pink {
  border: 1px solid #ff00d0 !important;
  box-shadow: 0 0 6px 0px #FF00D0 !important;
}

.simplebar-scrollbar:before {
  background: #3f3f5c;
  border-radius: 10px;
}

.simplebar-track.simplebar-vertical {
  width: 10px;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}