@import 'src/variables.scss';

.HeaderAction {
  display: flex;
  align-items: center;
  gap: 24px;

  &__message {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__text {
    font-size: 14px;
    font-weight: 600;
    color: map-get($theme-colors, 'black');
    margin: 0;
  }
}