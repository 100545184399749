.MediaLoader {
  &__title {
    display: inline;
    margin-top: 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  &__icon {
    height: 32px;
    width: 32px;
    stroke: white;
  }

  &__iconEdit {
    width: 18px;
    height: 18px;
    stroke: white;
  }

  &__img {
    height: 135px;
    border-radius: 8px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__reset {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 50px;
    border: none;
    background: rgba($color: #05051D, $alpha: 0.7);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
  }

  &__fileDrop {
    height: 135px;
    padding: 0 43px;
    background: #05051D;
    border-radius: 8px;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
  }

  &__fileInputControl {
    display: inline-block;
    border: none;
    background: none;
    padding: 0;
    color: #FF00D0;
  }

  &__fileInput {
    display: none;
  }

  &__fileDropTarget {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    justify-content: center;
  }
}