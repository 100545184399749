@import 'src/variables.scss';

.PriceMauCard {
  padding: 24px;
  border-radius: 12px;
  background: #FFFFFF;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.10), 0px 0px 1px rgba(0, 0, 0, 0.25), 0px 1px 0px rgba(0, 0, 0, 0.10);

  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding: 14px;
  }

  &__title {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    color: map-get($fresh-theme-colors, 'black');
    margin: 0;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__slider {
    width: 100%;
    display: flex;
    align-items: center;
    height: 18px;
    margin-bottom: 18px;

    .slider {
      width: 100%;
      user-select: none;
    }
  }

  &__track {
    height: 4px;
    border-radius: 5px;
    margin-top: -2px;
    background-color: map-get($fresh-theme-colors, 'pink');

    &_selected {
      background-color: map-get($fresh-theme-colors, 'grey-light');
    }
  }

  &__titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  &__mauLimit {
    margin: 0;
  }

  &__thumb {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: map-get($fresh-theme-colors, 'pink');
    border: 1px solid #FFFFFF;
    display: inline-block;
    transform: translateY(-50%);
    cursor: grab;
  }

  &__price {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: map-get($fresh-theme-colors, 'black');
    margin-bottom: 24px;
  }

  &__btn {
    width: 232px;

    &_disabled {
      opacity: 1 !important;

      &:hover {
        background-color: #FFFFFF;
      }
    }
  }
}